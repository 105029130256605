<template>
  <div class="about">
    <div class="section white full-height">
      <div class="row valign">
        <div class="col s8 offset-s2 m6 offset-m3 blog-body dsgvo">
         <br><br>   <!-- text -->
<span style="font-size:22px;"><strong>Datenschutzerkl&auml;rung</strong></span><br />
<br />
<span style="font-size:14px;">Soweit nachstehend keine anderen Angaben gemacht werden, ist die Bereitstellung Ihrer personenbezogenen Daten weder gesetzlich oder vertraglich vorgeschrieben, noch f&uuml;r einen Vertragsabschluss erforderlich. Sie sind zur Bereitstellung der Daten nicht verpflichtet. Eine Nichtbereitstellung hat keine Folgen. Dies gilt nur soweit bei den nachfolgenden Verarbeitungsvorg&auml;ngen keine anderweitige Angabe gemacht wird.<br />
&quot;Personenbezogene Daten&quot; sind alle Informationen, die sich auf eine identifizierte oder identifizierbare nat&uuml;rliche Person beziehen.</span><br />
<br />
<div>
<strong><span style="font-size:14px;">Server-Logfiles</span></strong></div>
<div>
<span style="font-size:14px;">Sie k&ouml;nnen unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen.&nbsp;</span></div>
<div>
<span style="font-size:14px;">Bei jedem Zugriff auf unsere Website werden an uns oder unseren Webhoster / IT-Dienstleister Nutzungsdaten durch Ihren Internet Browser &uuml;bermittelt und in Protokolldaten (sog. Server-Logfiles) gespeichert. Zu diesen gespeicherten Daten geh&ouml;ren z.B. der Name der aufgerufenen Seite, Datum und Uhrzeit des Abrufs, die IP-Adresse, die &uuml;bertragene Datenmenge und der anfragende Provider. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem &uuml;berwiegenden berechtigten Interesse an der Gew&auml;hrleistung eines st&ouml;rungsfreien Betriebs unserer Website sowie zur Verbesserung unseres Angebotes.&nbsp;</span><br />
&nbsp;</div>
<span style="font-size:20px;"><em><strong>Kontakt</strong></em></span><br />
<br />
<span style="font-size:14px;"><strong>Verantwortlicher</strong></span><strong><span style="font-size:14px;"></span></strong><br />
<span style="font-size:14px;">Kontaktieren Sie uns auf Wunsch. Die Kontaktdaten des f&uuml;r die Datenverarbeitung Verantwortlichen finden Sie in unserem Impressum.</span><br />
<br />
<p dir="ltr">
<span style="font-size:14px;"><strong>Initiativ-Kontaktaufnahme des Kunden per E-Mail</strong><br />
Wenn Sie per E-Mail initiativ mit uns in Gesch&auml;ftskontakt treten, erheben wir Ihre personenbezogenen Daten (Name, E-Mail-Adresse, Nachrichtentext) nur in dem von Ihnen zur Verf&uuml;gung gestellten Umfang. Die Datenverarbeitung dient der Bearbeitung und Beantwortung Ihrer Kontaktanfrage.<br />
Wenn die Kontaktaufnahme der Durchf&uuml;hrung vorvertraglichen Ma&szlig;nahmen (bspw. Beratung bei Kaufinteresse, Angebotserstellung) dient oder einen bereits zwischen Ihnen und uns geschlossenen Vertrag betrifft, erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO.<br />
Erfolgt die Kontaktaufnahme aus anderen Gr&uuml;nden erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem &uuml;berwiegenden berechtigten Interesse an der Bearbeitung und Beantwortung Ihrer Anfrage. <em><strong>In diesem Fall haben Sie das Recht, aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 Abs. 1 lit. f DSGVO beruhenden Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.</strong></em><br />
Ihre E-Mail-Adresse nutzen wir nur zur Bearbeitung Ihrer Anfrage. Ihre Daten werden anschlie&szlig;end unter Beachtung gesetzlicher Aufbewahrungsfristen gel&ouml;scht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.</span><br />
&nbsp;</p>
<span style="font-size:14px;"><strong>Erhebung und Verarbeitung bei Nutzung des Kontaktformulars </strong><br />
Bei der Nutzung des Kontaktformulars erheben wir Ihre personenbezogenen Daten (Name, E-Mail-Adresse, Nachrichtentext) nur in dem von Ihnen zur Verf&uuml;gung gestellten Umfang. Die Datenverarbeitung dient dem Zweck der Kontaktaufnahme.</span><br />
<span style="font-size: 14px;">Wenn die Kontaktaufnahme der Durchf&uuml;hrung vorvertraglichen Ma&szlig;nahmen (bspw. Beratung bei Kaufinteresse, Angebotserstellung) dient oder einen bereits zwischen Ihnen und uns geschlossenen Vertrag betrifft, erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO.</span><br style="font-size: 14px;" />
<span style="font-size: 14px;">Erfolgt die Kontaktaufnahme aus anderen Gr&uuml;nden erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem &uuml;berwiegenden berechtigten Interesse an der Bearbeitung und Beantwortung Ihrer Anfrage.&nbsp;</span><em style="font-size: 14px;"><strong>In diesem Fall haben Sie das Recht, aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 Abs. 1 lit. f DSGVO beruhenden Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.</strong></em><br style="font-size: 14px;" />
<span style="font-size: 14px;">Ihre E-Mail-Adresse nutzen wir nur zur Bearbeitung Ihrer Anfrage. Ihre Daten werden anschlie&szlig;end unter Beachtung gesetzlicher Aufbewahrungsfristen gel&ouml;scht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.</span><br />
<br />
<p dir="ltr">
<span style="font-size:14px;"><strong>WhatsApp Business</strong></span><br />
<span style="font-size: 14px;">Wenn Sie per WhatsApp mit uns in Gesch&auml;ftskontakt treten, nutzen wir hierf&uuml;r die Version WhatsApp Business der WhatsApp Ireland Limited (4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland; &ldquo;WhatsApp&rdquo;). Soweit Sie Ihren Aufenthalt au&szlig;erhalb des Europ&auml;ischen Wirtschaftsraumes haben, wird dieser Dienst durch die WhatsApp Inc. (1601 Willow Road, Menlo Park, CA 94025, USA) bereitgestellt.&nbsp;</span><br />
<span style="font-size: 14px;">Die Datenverarbeitung dient der Bearbeitung und Beantwortung Ihrer Kontaktanfrage. Zu diesem Zweck erheben und verarbeiten wir Ihre bei WhatsApp hinterlegt Mobilfunktnummer, falls bereitgestellt Ihren Namen sowie weitere Daten in dem von Ihnen zur Verf&uuml;gung gestellten Umfang. Wir verwenden f&uuml;r den Dienst ein mobiles Endger&auml;t, in dessen Adressbuch ausschlie&szlig;lich Daten von Nutzern gespeichert sind, die uns &uuml;ber WhatsApp kontaktiert haben. Eine Weitergabe personenbezogener Daten an WhatsApp, ohne dass Sie hierin bereits gegen&uuml;ber WhatsApp eingewilligt haben, erfolgt damit nicht.</span><br />
<span style="font-size: 14px;">Ihre Daten werden von WhatsApp an Server der Facebook Inc. in den USA &uuml;bermittelt.</span><br />
<span style="font-size: 14px;">Wenn die Kontaktaufnahme der Durchf&uuml;hrung vorvertraglichen Ma&szlig;nahmen (bspw. Beratung bei Kaufinteresse, Angebotserstellung) dient oder einen bereits zwischen Ihnen und uns geschlossenen Vertrag betrifft, erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO.</span><br />
<span style="font-size: 14px;">Erfolgt die Kontaktaufnahme aus anderen Gr&uuml;nden erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem &uuml;berwiegenden berechtigten Interesse am Bereitstellen einer schnellen und einfachen Kontaktaufnahme sowie an der Beantwortung Ihrer Anfrage. <strong><em>In diesem Fall haben Sie das Recht, aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 Abs. 1 lit. f DSGVO beruhenden Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.</em></strong></span><br />
<span style="font-size: 14px;">Ihre personenbezogenen Daten nutzen wir nur zur Bearbeitung Ihrer Anfrage. Ihre Daten werden anschlie&szlig;end unter Beachtung gesetzlicher Aufbewahrungsfristen gel&ouml;scht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.</span><br />
<span style="font-size: 14px;">N&auml;here Informationen zu Nutzungsbedingungen und Datenschutz bei Nutzung von WhatsApp finden Sie unter </span><a href="https://www.whatsapp.com/legal/#terms-of-service" style="font-size: 14px;" target="_blank">https://www.whatsapp.com/legal/#terms-of-service</a><span style="font-size: 14px;"> und </span><a href="https://www.whatsapp.com/legal/#privacy-policy" style="font-size: 14px;" target="_blank">https://www.whatsapp.com/legal/#privacy-policy</a><span style="font-size: 14px;">.</span><br />
&nbsp;</p>
<br />
<span style="font-size:20px;"><em><strong>Kundenkonto&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bestellungen&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong></em></span><br />
<br />
<span style="font-size:14px;"><strong>Kundenkonto</strong><br />
Bei der Er&ouml;ffnung eines Kundenkontos erheben wir Ihre personenbezogenen Daten in dem dort angegebenen Umfang. Die Datenverarbeitung dient dem Zweck, Ihr Einkaufserlebnis zu verbessern und die Bestellabwicklung zu vereinfachen. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO mit Ihrer Einwilligung. Sie k&ouml;nnen Ihre Einwilligung jederzeit durch Mitteilung an uns widerrufen, ohne dass die Rechtm&auml;&szlig;igkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung ber&uuml;hrt wird. Ihr Kundenkonto wird anschlie&szlig;end gel&ouml;scht.</span><br />
<br />
<div>
<strong><span style="font-size:14px;">Erhebung, Verarbeitung und Weitergabe personenbezogener Daten bei Bestellungen</span></strong></div>
<div>
<span style="font-size:14px;">Bei der Bestellung erheben und verarbeiten wir Ihre personenbezogenen Daten nur, soweit dies zur Erf&uuml;llung und Abwicklung Ihrer Bestellung sowie zur Bearbeitung Ihrer Anfragen erforderlich ist. Die Bereitstellung der Daten ist f&uuml;r den Vertragsschluss erforderlich. Eine Nichtbereitstellung hat zur Folge, dass kein Vertrag geschlossen werden kann. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO und ist f&uuml;r die Erf&uuml;llung eines Vertrags mit Ihnen erforderlich.&nbsp;</span></div>
<div>
<span style="font-size:14px;">Eine Weitergabe Ihrer Daten erfolgt dabei beispielsweise an die von Ihnen gew&auml;hlten Versandunternehmen und Dropshipping Anbieter, Zahlungsdienstleister, Diensteanbieter f&uuml;r die Bestellabwicklung und IT-Dienstleister. In allen F&auml;llen beachten wir strikt die gesetzlichen Vorgaben. Der Umfang der Daten&uuml;bermittlung beschr&auml;nkt sich auf ein Mindestma&szlig;.</span><br />
&nbsp;</div>
<br />
<span style="font-size:20px;"><strong><em></em></strong></span><span style="font-size:20px;"><strong><em>   Bewertungen&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</em></strong></span><span style="font-size:20px;"><strong><em>   Werbung&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</em></strong></span><br />
<br />
<span style="font-size:14px;"><strong>K&auml;ufersiegel-Kundenbewertung</strong>&nbsp;</span><br />
<span style="font-size: 14px;">Wir verwenden auf unserer Website das K&auml;ufersiegel-Kundenbewertungs-Tool der H&auml;ndlerbund Management AG (Torgauer Stra&szlig;e 233 B, 04347 Leipzig). Nach Ihrer Bestellung m&ouml;chten wir Sie bitten, Ihren Kauf bei uns zu bewerten und zu kommentieren.</span><br />
<span style="font-size: 14px;">Zu diesem Zweck werden Sie von uns per E-Mail angeschrieben, wobei wir uns hierbei des technischen Systems des Anbieters des K&auml;ufersiegel-Bewertungstools im Rahmen einer Auftragsverarbeitung bedienen.</span><br />
<span style="font-size: 14px;">Dabei erfolgt die Verarbeitung Ihrer Daten entweder mit Ihrer Einwilligung oder aufgrund unseres berechtigten Interesses.</span><br />
<span style="font-size: 14px;">Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs.1 lit. a DSGVO mit Ihrer Einwilligung, sofern Sie dem Erhalt der Bewertungsaufforderung ausdr&uuml;cklich zugestimmt haben. Sie k&ouml;nnen die Einwilligung jederzeit unter Nutzung des entsprechenden Links in der E-Mail widerrufen, ohne dass die Rechtm&auml;&szlig;igkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung ber&uuml;hrt wird. Ihre E-Mail-Adresse wird danach aus dem Verteiler entfernt.</span><br />
<span style="font-size: 14px;">Die Verarbeitung ohne ausdr&uuml;ckliche Einwilligung erfolgt auf Grundlage des Art. 6 Abs.1 lit. f DSGVO aus dem berechtigten Interesse an wahrheitsgem&auml;&szlig;en, verifizierten Bewertungen unserer Leistungen im Rahmen der Direktwerbung. Zu diesem Zweck versenden wir in elektronischer Form eine Bewertungsaufforderung f&uuml;r eigene Waren oder Dienstleistungen, die Sie bereits bei uns erworben haben. Die Versendung erfolgt an die E-Mail-Adresse, die wir im Rahmen des Verkaufes einer Ware oder Dienstleistung von Ihnen erhalten haben. Die Versendung der Bewertungsaufforderung steht unter dem Vorbehalt, dass Sie dieser Verwendung Ihrer E-Mail-Adresse nicht widersprochen haben.<br />
<strong><em>Der Widerspruch ist jederzeit durch Mitteilung an uns m&ouml;glich. Die Kontaktdaten f&uuml;r die Aus&uuml;bung des Widerspruchs finden Sie im Impressum.</em></strong> Sie k&ouml;nnen auch den daf&uuml;r vorgesehenen Link in der Bewertungsaufforderung nutzen. Hierf&uuml;r entstehen keine anderen als die &Uuml;bermittlungskosten nach den Basistarifen.</span><br />
<span style="font-size: 14px;">Die in diesem Zusammenhang im technischen System des K&auml;ufersiegel-Bewertungstools gespeicherten personenbezogenen Daten werden 3 Monate nach der zur Bewertung erfassten Warenlieferung gel&ouml;scht.</span><br />
<br />
<div>
<span style="font-size:14px;"><strong>Shopauskunft-Kundenbewertung</strong><br />
Wir verwenden f&uuml;r unsere Website das Bewertungs-Tool &ldquo;shopauskunft.de&rdquo; der H&auml;ndlerbund Management AG (Torgauer Stra&szlig;e 233 B, 04347 Leipzig; &quot;Shopauskunft&quot;).<br />
Nach Ihrer Bestellung m&ouml;chten wir Sie bitten, Ihren Kauf bei uns zu bewerten und zu kommentieren. Zu diesem Zweck werden Sie von uns per E-Mail angeschrieben, wobei wir uns hierbei des technischen Systems&nbsp;&quot;Rechtssichere Bewertungsanfrage (RBA)&quot; </span><span style="font-size: 14px;">bedienen. Dabei verarbeiten wir die Daten zu Ihrer Bestellung (Bestellnummer/Rechnungsnummer, Einkaufswert und Versandkosten) sowie Ihre E-Mail-Adresse.</span></div>
<div>
<span style="font-size:14px;">Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs.1 lit. a DSGVO mit Ihrer Einwilligung, sofern Sie der Weitergabe Ihrer Daten und dem Erhalt der Bewertungsaufforderung ausdr&uuml;cklich zugestimmt haben.<br />
Sie k&ouml;nnen die Einwilligung jederzeit unter Nutzung des entsprechenden Links in der E-Mail oder durch Mitteilung an uns widerrufen, ohne dass die Rechtm&auml;&szlig;igkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung ber&uuml;hrt wird.<br />
Weitere Informationen zum Datenschutz bei Verwendung von Shopauskunft finden Sie unter:&nbsp;<br />
<a href="https://www.shopauskunft.de/datenschutz" target="_blank">https://www.shopauskunft.de/datenschutz</a>.</span><br />
&nbsp;</div>
<div>
<strong><span style="font-size:14px;">Shopauskunft Widget</span></strong></div>
<div>
<span style="font-size:14px;">Auf unserer Webseite ist das Widget von Shopauskunft eingebunden. Dies dient dem Zweck Anzahl und Ergebnis unsere bisher &uuml;ber Shopauskunft erhaltenen Bewertungen anzuzeigen und damit zu werben.</span></div>
<div>
<span style="font-size:14px;">Zur Anzeige des Widgets ist es technisch notwendig, Nutzungsdaten durch Ihren Internet Browser an den Server von Shopauskunft zu &uuml;bermitteln und in Protokolldaten (sog. Server-Logfiles) f&uuml;r 7 Tage zu speichern. Zu diesen gespeicherten Daten geh&ouml;ren der Name und URL der abgerufenen Datei, Datum und Uhrzeit des Abrufs, die IP-Adresse des anfragenden Rechners, Website, von der aus der Zugriff erfolgt (Referrer-URL), der verwendete Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers.</span></div>
<div>
<span style="font-size:14px;">Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem &uuml;berwiegenden berechtigten Interesse an der Bewerbung unserer Angebote durch die Darstellung der bereits erhaltenen Kundenbewertungen. Eine Speicherung dieser Daten zusammen mit anderen personenbezogen Daten erfolgt nicht.&nbsp;</span><br />
&nbsp;</div>
<span style="font-size:14px;"><strong>Websitelogo f&uuml;r Google Kundenrezensionen</strong><br />
Auf unserer Webseite ist das Websitelogo f&uuml;r Google Kundenrezensionen der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; &bdquo;Google&ldquo;) eingebunden.<br />
Die Einbindung dient dem Zweck, Anzahl und Ergebnis unsere bisher &uuml;ber Google erhaltenen Bewertungen anzuzeigen und mit der Teilnahme an diesem Programm zu werben.<br />
Um das Logo auf unserer Website anzuzeigen und Ihnen bei Google personalisierte Werbeanzeigen anzuzeigen, setzt Google Cookies ein. Dabei kann u.a. Ihre IP-Adresse verarbeitet und an Google &uuml;bermittelt werden.<br />
Ihre Daten werden gegebenenfalls in die USA &uuml;bermittelt. F&uuml;r die USA ist kein Angemessenheitsbeschluss der EU-Kommission vorhanden. Die Daten&uuml;bermittlung erfolgt u.a. auf Grundlage von Standardvertragsklauseln als geeignete Garantien f&uuml;r den Schutz der personenbezogenen Daten, einsehbar unter: <a href="https://privacy.google.com/businesses/controllerterms/" target="_blank">https://privacy.google.com/businesses/controllerterms/</a>.<br />
Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt auf Grundlage des &sect; 15 Abs. 3 S. 1 TMG. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem &uuml;berwiegenden berechtigten Interesse an der optimalen Vermarktung unserer Angebote durch die Darstellung der bereits erhaltenen Kundenbewertungen. <em><strong>Sie haben das Recht aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser Verarbeitung Sie betreffender personenbezogener Daten zu widersprechen.</strong></em> Sie k&ouml;nnen in den Einstellungen f&uuml;r Werbung bei Google f&uuml;r Sie personalisierte Werbung deaktivieren. Eine Anleitung dazu finden Sie unter <a href="https://support.google.com/ads/answer/2662922?hl=de" target="_blank">https://support.google.com/ads/answer/2662922?hl=de</a>. Alternativ k&ouml;nnen Sie die Verwendung von Cookies durch Drittanbieter verhindern, indem Sie die Deaktivierungsseite der Netzwerkwerbeinitiative (Network Advertising Initiative) unter <a href="https://www.networkadvertising.org/choices/" target="_blank">https://www.networkadvertising.org/choices/</a> aufrufen und die dort genannten weiterf&uuml;hrenden Information zum Opt-Out umsetzen.<br />
N&auml;here Informationen zu Nutzungsbedingungen und Datenschutz bei Verwendung von Google Kundenrezensionen finden Sie unter <a href="https://www.google.com/shopping/customerreviews/static/tos/de/1_01_tos.html" target="_blank">https://www.google.com/shopping/customerreviews/static/tos/de/1_01_tos.html</a> sowie unter <a href="https://policies.google.com/privacy?hl=de" target="_blank">https://policies.google.com/privacy?hl=de</a></span><br />
<br />
<span style="font-size: 14px;"><strong>Verwendung Ihrer personenbezogenen Daten f&uuml;r die Zusendung postalischer Werbung</strong></span><br />
<span style="font-size: 14px;">Wir nutzen Ihre personenbezogenen Daten (Name, Anschrift), die wir im Rahmen des Verkaufs einer Ware oder Dienstleistung erhalten haben, um Ihnen postalische Werbung zuzusenden, sofern Sie dieser Verwendung nicht widersprochen haben. Die Bereitstellung dieser Daten ist f&uuml;r den Vertragsschluss erforderlich. Eine Nichtbereitstellung hat zur Folge, dass kein Vertrag geschlossen werden kann.</span><br />
<span style="font-size: 14px;">Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem &uuml;berwiegenden berechtigten Interesse an Direktwerbung. <em><strong>Sie k&ouml;nnen dieser Verwendung Ihrer Adressdaten jederzeit durch Mitteilung an uns widersprechen. </strong></em>Die Kontaktdaten f&uuml;r die Aus&uuml;bung des Widerspruchs finden Sie im Impressum.</span><br />
<br />
<span style="font-size:14px;"><strong>Verwendung der E-Mail-Adresse f&uuml;r die Zusendung von Newslettern</strong><br />
Wir nutzen Ihre E-Mail-Adresse unabh&auml;ngig von der Vertragsabwicklung ausschlie&szlig;lich f&uuml;r eigene Werbezwecke zum Newsletterversand, sofern Sie dem ausdr&uuml;cklich zugestimmt haben. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO mit Ihrer Einwilligung. Sie k&ouml;nnen die Einwilligung jederzeit widerrufen, ohne dass die Rechtm&auml;&szlig;igkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung ber&uuml;hrt wird. Sie k&ouml;nnen dazu den Newsletter jederzeit unter Nutzung des entsprechenden Links im Newsletter oder durch Mitteilung an uns abbestellen. Ihre E-Mail-Adresse wird danach aus dem Verteiler entfernt.&nbsp;</span><br />
<br />
<span style="font-size:14px;">Ihre Daten werden dabei an einen Dienstleister f&uuml;r E-Mail-Marketing im Rahmen einer Auftragsverarbeitung weitergegeben. Eine Weitergabe an sonstige Dritte erfolgt nicht.</span><br />
<br />
<span style="font-size:14px;"><strong>Verwendung der E-Mail-Adresse f&uuml;r die Zusendung von Direktwerbung </strong><br />
Wir nutzen Ihre E-Mail-Adresse, die wir im Rahmen des Verkaufes einer Ware oder Dienstleistung erhalten haben, f&uuml;r die elektronische &Uuml;bersendung von Werbung f&uuml;r eigene Waren oder Dienstleistungen, die denen &auml;hnlich sind, die Sie bereits bei uns erworben haben, soweit Sie dieser Verwendung nicht widersprochen haben. Die Bereitstellung der E-Mail-Adresse ist f&uuml;r den Vertragsschluss erforderlich. Eine Nichtbereitstellung hat zur Folge, dass kein Vertrag geschlossen werden kann. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem &uuml;berwiegenden berechtigten Interesse an Direktwerbung. <em><strong>Sie k&ouml;nnen dieser Verwendung Ihrer E-Mail-Adresse jederzeit durch Mitteilung an uns widersprechen.</strong></em> <em><strong>Die Kontaktdaten f&uuml;r die Aus&uuml;bung des Widerspruchs finden Sie im Impressum.</strong></em> Sie k&ouml;nnen auch den daf&uuml;r vorgesehenen Link in der Werbe-E-Mail nutzen. Hierf&uuml;r entstehen keine anderen als die &Uuml;bermittlungskosten nach den Basistarifen.</span><br />
<br />
<div>
<br />
<span style="font-size:20px;"><strong><em>Cookies</em></strong></span></div>
<div>
&nbsp;</div>
<div>
<span style="font-size:14px;">Unsere Website verwendet Cookies. Cookies sind kleine Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem Computersystem eines Nutzers gespeichert werden. Ruft ein Nutzer eine Website auf, so kann ein Cookie auf dem Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enth&auml;lt eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen der Website erm&ouml;glicht.</span></div>
<div>
&nbsp;</div>
<div>
<span style="font-size:14px;">Cookies werden auf Ihrem Rechner gespeichert. Daher haben Sie die volle Kontrolle &uuml;ber die Verwendung von Cookies. Durch die Auswahl entsprechender technischer Einstellungen in Ihrem Internetbrowser k&ouml;nnen Sie vor dem Setzen von Cookies benachrichtigt werden und &uuml;ber die Annahme einzeln entscheiden sowie die Speicherung der Cookies und &Uuml;bermittlung der enthaltenen Daten verhindern. Bereits gespeicherte Cookies k&ouml;nnen jederzeit gel&ouml;scht werden. Wir weisen Sie jedoch darauf hin, dass Sie dann gegebenenfalls nicht s&auml;mtliche Funktionen dieser Website vollumf&auml;nglich werden nutzen k&ouml;nnen.</span></div>
<div>
&nbsp;</div>
<div>
<span style="font-size:14px;">Unter den nachstehenden Links k&ouml;nnen Sie sich informieren, wie Sie die Cookies bei den wichtigsten Browsern verwalten (u.a. auch deaktivieren) k&ouml;nnen:</span></div>
<div>
<span style="font-size:14px;">Chrome: <a href="https://support.google.com/accounts/answer/61416?hl=de" target="_blank">https://support.google.com/accounts/answer/61416?hl=de</a><br />
Internet Explorer: <a href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies</a></span></div>
<div>
<span style="font-size:14px;">Mozilla Firefox: <a href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen" target="_blank">https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen</a></span></div>
<div>
<span style="font-size:14px;">Safari: <a href="https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/mac" target="_blank">https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a></span></div>
<div>
<br />
&nbsp;</div>
<div>
<strong><span style="font-size:14px;">Technisch notwendige Cookies</span></strong></div>
<div>
<span style="font-size:14px;">Soweit nachstehend in der Datenschutzerkl&auml;rung keine anderen Angaben gemacht werden setzen wir nur diese technisch notwendigen Cookies zu dem Zweck ein, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Des Weiteren erm&ouml;glichen Cookies unseren Systemen, Ihren Browser auch nach einem Seitenwechsel zu erkennen und Ihnen Services anzubieten. Einige Funktionen unserer Internetseite k&ouml;nnen ohne den Einsatz von Cookies nicht angeboten werden. F&uuml;r diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt wird.</span></div>
<div>
&nbsp;</div>
<div>
<span style="font-size:14px;">Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt auf Grundlage des &sect; 15 Abs. 3 S. 1 TMG. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem &uuml;berwiegenden berechtigten Interesse&nbsp;an der Gew&auml;hrleistung der optimalen Funktionalit&auml;t der Website sowie einer nutzerfreundlichen und effektiven Gestaltung unseres Angebots.</span></div>
<div>
<span style="font-size:14px;"><em><strong>Sie haben das Recht aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.</strong></em></span><br />
&nbsp;</div>
<br />
<span style="font-size:20px;"><em><strong>Analyse&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Werbetracking&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Kommunikation&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong></em></span><br />
<br />
<span style="font-size:14px;"><strong>Verwendung von Google Analytics</strong><br />
Wir verwenden auf unserer Website den Webanalysedienst Google Analytics der Google LLC. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; &bdquo;Google&ldquo;). Soweit Sie Ihren gew&ouml;hnlichen Aufenthalt im Europ&auml;ischen Wirtschaftsraum oder der Schweiz haben, ist Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland) der f&uuml;r Ihre Daten zust&auml;ndige Verantwortliche. Google Ireland Limited ist demnach das mit Google verbundene Unternehmen, welches f&uuml;r die Verarbeitung Ihrer Daten und die Einhaltung der anwendbaren Datenschutzgesetze verantwortlich ist.<br />
Die Datenverarbeitung dient dem Zweck der Analyse dieser Website und ihrer Besucher sowie f&uuml;r Marketing- und Werbezwecke. Dazu wird Google im Auftrag des Betreibers dieser Website die gewonnenen Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports &uuml;ber die Websiteaktivit&auml;ten zusammenzustellen und um weitere, mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegen&uuml;ber dem Websitebetreiber zu erbringen. Dabei k&ouml;nnen u.a. folgende Informationen erhoben werden: IP-Adresse, Datum und Uhrzeit des Seitenaufrufs, Klickpfad, Informationen &uuml;ber den von Ihnen verwendeten Browser und das von Ihnen verwendete Device (Ger&auml;t), besuchte Seiten, Referrer-URL (Webseite, &uuml;ber die Sie unsere Webseite aufgerufen haben), Standortdaten, Kaufaktivit&auml;ten. Die im Rahmen von Google Analytics von Ihrem Browser &uuml;bermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengef&uuml;hrt.<br />
Google Analytics verwendet Technologien wie Cookies, Webspeicher im Browser und Z&auml;hlpixel, die eine Analyse der Benutzung der Website durch Sie erm&ouml;glichen. Die dadurch erzeugten Informationen &uuml;ber Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA &uuml;bertragen und dort gespeichert.&nbsp;F&uuml;r die USA ist kein Angemessenheitsbeschluss der EU-Kommission vorhanden. Die Daten&uuml;bermittlung erfolgt u.a auf Grundlage von Standardvertragsklauseln&nbsp;als geeignete Garantien f&uuml;r den Schutz der personenbezogenen Daten, einsehbar unter: <a href="https://policies.google.com/privacy/frameworks" target="_blank">https://policies.google.com/privacy/frameworks</a>. Sowohl Google als auch staatliche US-Beh&ouml;rden haben Zugriff auf Ihre Daten. Ihre Daten k&ouml;nnen von Google mit anderen Daten, wie beispielsweise Ihrem Suchverlauf, Ihren pers&ouml;nlichen Accounts, Ihren Nutzungsdaten anderer Ger&auml;te und allen anderen Daten, die Google zu Ihnen vorliegen hat, verkn&uuml;pft werden.<br />
Auf dieser Website ist die IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europ&auml;ischen Union oder in anderen Vertragsstaaten des Abkommens &uuml;ber den Europ&auml;ischen Wirtschaftsraum zuvor gek&uuml;rzt. Nur in Ausnahmef&auml;llen wird die volle IP-Adresse an einen Server von Google in den USA &uuml;bertragen und dort gek&uuml;rzt.<br />
Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt auf Grundlage des &sect; 15 Abs. 3 S. 1 TMG. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem &uuml;berwiegenden berechtigten Interesse an der bedarfsgerechten und zielgerichteten Gestaltung der Website. <em><strong>Sie haben das Recht aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.</strong></em><br />
Sie k&ouml;nnen die Erfassung der durch Google Analytics erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verf&uuml;gbare Browser-Plug-in herunterladen und installieren: <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank">https://tools.google.com/dlpage/gaoptout?hl=de</a><br />
Um die Datenerfassung und -speicherung durch Google Analytics ger&auml;te&uuml;bergreifend zu verhindern, k&ouml;nnen Sie einen Opt-Out-Cookie setzen. Opt-Out-Cookies verhindern die zuk&uuml;nftige Erfassung Ihrer Daten beim Besuch dieser Website. Sie m&uuml;ssen das Opt-Out auf allen genutzten Systemen und Ger&auml;ten durchf&uuml;hren, damit dies umfassend wirkt. Wenn Sie das Opt-out-Cookie l&ouml;schen, werden wieder Anfragen an Google &uuml;bermittelt. Wenn Sie hier klicken, wird das Opt-Out-Cookie gesetzt: <a href="javascript:gaOptout()" id="ga-opt-out">Google Analytics deaktivieren</a>.<br />
N&auml;here Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter <a href="https://www.google.com/analytics/terms/de.html" target="_blank">https://www.google.com/analytics/terms/de.html</a> bzw. unter <a href="https://www.google.de/intl/de/policies/" target="_blank">https://www.google.de/intl/de/policies/</a> sowie unter <a href="https://policies.google.com/technologies/cookies?hl=de" target="_blank">https://policies.google.com/technologies/cookies?hl=de</a>.</span><br />
<div>
&nbsp;</div>
<span style="font-size:14px;"><strong>Verwendung von PHP Web Stat</strong><br />
Wir verwenden auf unserer Website das Analysetool PHP Web Stat der PHP Web Stat (Sternbuschweg 2, 46562 Voerde; &ldquo;PHP Web Stat&rdquo;).<br />
Die Datenverarbeitung dient dem Zweck der Analyse dieser Website und ihrer Besucher. Dazu werden Daten zu Marketing- und Optimierungszwecken gesammelt und gespeichert. Aus diesen Daten k&ouml;nnen unter einem Pseudonym Nutzungsprofile erstellt werden. Hierzu k&ouml;nnen Cookies eingesetzt werden. Die Cookies erm&ouml;glichen die Wiedererkennung des Internet-Browsers. Die mit den PHP Web Stat-Technologien erhobenen Daten werden ohne die gesondert erteilte Zustimmung des Betroffenen nicht dazu benutzt, den Besucher dieser Website pers&ouml;nlich zu identifizieren und nicht mit personenbezogenen Daten &uuml;ber den Tr&auml;ger des Pseudonyms zusammengef&uuml;hrt.<br />
Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt auf Grundlage des &sect; 15 Abs. 3 S. 1 TMG. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem &uuml;berwiegenden berechtigten Interesse an der bedarfsgerechten und zielgerichteten Gestaltung der Website. <em><strong>Sie haben das Recht aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.</strong></em><br />
Sie k&ouml;nnen die Speicherung von Cookies durch die Auswahl entsprechender technischer Einstellungen Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht s&auml;mtliche Funktionen dieser Website vollumf&auml;nglich werden nutzen k&ouml;nnen.</span><br />
<br />
<div>
<span style="font-size:14px;"><strong>Verwendung von Google Ads Conversion-Tracking</strong><br />
Wir verwenden auf unserer Website das Online-Werbeprogramm &bdquo;Google Ads&ldquo; und in diesem Rahmen Conversion-Tracking (Besuchsaktionsauswertung). Das Google Conversion Tracking ist ein Analysedienst der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; &bdquo;Google&ldquo;). Soweit Sie Ihren gew&ouml;hnlichen Aufenthalt im Europ&auml;ischen Wirtschaftsraum oder der Schweiz haben, ist Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland) der f&uuml;r Ihre Daten zust&auml;ndige Verantwortliche. Google Ireland Limited ist demnach das mit Google verbundene Unternehmen, welches f&uuml;r die Verarbeitung Ihrer Daten und die Einhaltung der anwendbaren Datenschutzgesetze verantwortlich ist.<br />
Wenn Sie auf eine von Google geschaltete Anzeige klicken, wird ein Cookie f&uuml;r das Conversion-Tracking auf Ihrem Rechner abgelegt. Diese Cookies haben eine begrenzte G&uuml;ltigkeit, enthalten keine personenbezogenen Daten und dienen somit nicht der pers&ouml;nlichen Identifizierung. Wenn Sie bestimmte Seiten unserer Website besuchen und das Cookie noch nicht abgelaufen ist, k&ouml;nnen Google und wir erkennen, dass Sie auf die Anzeige geklickt haben und zu dieser Seite weitergeleitet wurden. Jeder Google Ads-Kunde erh&auml;lt ein anderes Cookie. Somit besteht keine M&ouml;glichkeit, dass Cookies &uuml;ber die Websites von Ads-Kunden nachverfolgt werden k&ouml;nnen.<br />
Die Informationen, die mit Hilfe des Conversion-Cookie eingeholt werden, dienen dem Zweck Conversion-Statistiken zu erstellen. Hierbei erfahren wir die Gesamtanzahl der Nutzer, die auf eine unserer Anzeigen geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Wir erhalten jedoch keine Informationen, mit denen sich Nutzer pers&ouml;nlich identifizieren lassen.<br />
Ihre Daten werden gegebenenfalls in die USA &uuml;bermittelt.&nbsp;F&uuml;r die USA ist kein Angemessenheitsbeschluss der EU-Kommission vorhanden. Die Daten&uuml;bermittlung erfolgt u.a auf Grundlage von Standardvertragsklauseln als geeignete Garantien f&uuml;r den Schutz der personenbezogenen Daten, einsehbar unter: <a href="https://policies.google.com/privacy/frameworks" target="_blank">https://policies.google.com/privacy/frameworks</a>.<br />
Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt auf Grundlage des &sect; 15 Abs. 3 S. 1 TMG. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem &uuml;berwiegenden berechtigten Interesse&nbsp;die Seitenbesucher zielgerichtet mit interessenbezogener Werbung anzusprechen.&nbsp;<em><strong>Sie haben das Recht aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.</strong></em><br />
Sie k&ouml;nnen in den Einstellungen f&uuml;r Werbung bei Google f&uuml;r Sie personalisierte Werbung deaktivieren. Eine Anleitung dazu finden Sie unter <a href="https://support.google.com/ads/answer/2662922?hl=de" target="_blank">https://support.google.com/ads/answer/2662922?hl=de</a>&nbsp;<br />
Alternativ k&ouml;nnen Sie die Verwendung von Cookies durch Drittanbieter verhindern, indem Sie die Deaktivierungsseite der Netzwerkwerbeinitiative (Network Advertising Initiative) unter <a href="https://www.networkadvertising.org/choices/" target="_blank">https://www.networkadvertising.org/choices/</a> aufrufen und die dort genannten weiterf&uuml;hrenden Information zum Opt-Out umsetzen. Sie werden sodann nicht in die Conversion-Tracking Statistiken aufgenommen.<br />
N&auml;here Informationen sowie die Datenschutzerkl&auml;rung von Google finden Sie unter: <a href="https://www.google.de/policies/privacy/" target="_blank">https://www.google.de/policies/privacy/</a></span><br />
&nbsp;</div>
<div>
<span style="font-size:14px;"><strong>Verwendung von Google AdSense </strong><br />
Wir verwenden auf unserer Website die AdSense-Funktion der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; &bdquo;Google&ldquo;). Soweit Sie Ihren gew&ouml;hnlichen Aufenthalt im Europ&auml;ischen Wirtschaftsraum oder der Schweiz haben, ist Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland) der f&uuml;r Ihre Daten zust&auml;ndige Verantwortliche. Google Ireland Limited ist demnach das mit Google verbundene Unternehmen, welches f&uuml;r die Verarbeitung Ihrer Daten und die Einhaltung der anwendbaren Datenschutzgesetze verantwortlich ist. Die Datenverarbeitung dient dem Zweck, Werbefl&auml;chen auf der Website zu vermieten und auf diesen die Besucher der Website zielgerichtet mit interessenbezogener Werbung anzusprechen. Mittels dieser Funktion werden den Besuchern der Website des Anbieters personalisierte, interessenbezogene Werbung-Anzeigen aus dem Google Display-Netzwerk geschaltet. Dabei verwendet Google Cookies, die eine Analyse der Benutzung der Website durch Sie erm&ouml;glichen. Die durch den Cookie erzeugten Informationen &uuml;ber Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA &uuml;bertragen und dort gespeichert.&nbsp;F&uuml;r die USA ist kein Angemessenheitsbeschluss der EU-Kommission vorhanden. Die Daten&uuml;bermittlung erfolgt u.a auf Grundlage von Standardvertragsklauseln als geeignete Garantien f&uuml;r den Schutz der personenbezogenen Daten, einsehbar unter: <a href="https://policies.google.com/privacy/frameworks" target="_blank">https://policies.google.com/privacy/frameworks</a>. </span><span style="font-size: 14px;">Google wird diese Informationen gegebenenfalls an Dritte &uuml;bertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google in Verbindung bringen.</span></div>
<div>
<span style="font-size: 14px;">Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt auf Grundlage des &sect; 15 Abs. 3 S. 1 TMG. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem &uuml;berwiegenden berechtigten Inter</span><span style="font-size:14px;">esse&nbsp;die Seitenbesucher zielgerichtet mit interessenbezogener Werbung anzusprechen.&nbsp;</span><span style="font-size: 14px;"><em><strong>Sie haben das Recht aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.</strong></em></span><br style="font-size: 14px;" />
<span style="font-size:14px;">Sie k&ouml;nnen die Verwendung von Cookies durch Google dauerhaft deaktivieren, indem Sie dem nachfolgenden Link folgen und das dort bereitgestellte Plug-In herunterladen und installieren: <a href="https://support.google.com/ads/answer/7395996?hl=de" target="_blank">https://support.google.com/ads/answer/7395996?hl=de</a>. Alternativ k&ouml;nnen Sie die Verwendung von Cookies durch Drittanbieter verhindern, indem sie die Deaktivierungsseite der Netzwerkwerbeinitiative (Network Advertising Initiative) unter <a href="https://www.networkadvertising.org/choices/" target="_blank">https://www.networkadvertising.org/choices/ </a>aufrufen und die dort genannten weiterf&uuml;hrenden Information zum Opt-Out umsetzen. N&auml;here Informationen sowie die Datenschutzerkl&auml;rung von Google finden Sie unter: <a href="https://www.google.com/policies/technologies/ads/" target="_blank">https://www.google.com/policies/technologies/ads/</a> und <a href="https://www.google.de/policies/privacy/" target="_blank">https://www.google.de/policies/privacy/</a></span><br />
&nbsp;</div>
<div>
<span style="font-size:14px;"><strong>Verwendung des Live-Chat-Systems tawk.to</strong><br />
Wir verwenden auf unserer Website das Live-Chat-System der tawk.to inc. (187 East Warm Springs Rd, SB298, Las Vegas, NV 89119, USA, &ldquo;tawk.to&rdquo;).<br />
Die Datenverarbeitung dient dem Zweck der Kommunikation zwischen Ihnen und uns als Anbieter. Dabei werden zum Betrieb des Systems sowie zu Zwecken der Webanalyse anonymisierte Daten verarbeitet. Aus diesen Daten k&ouml;nnen unter einem Pseudonym Nutzerprofile erstellt werden, wobei Cookies eingesetzt werden k&ouml;nnen. Die Cookies erm&ouml;glichen die Wiedererkennung des Internet-Browsers.<br />
Die erhobenen Daten werden ohne die gesondert erteilte Zustimmung des Betroffenen nicht dazu benutzt, den Besucher dieser Website pers&ouml;nlich zu identifizieren.<br />
Ihre Daten werden gegebenenfalls in die USA &uuml;bermittelt.&nbsp;F&uuml;r die USA ist kein Angemessenheitsbeschluss der EU-Kommission vorhanden.<br />
Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt auf Grundlage des &sect; 15 Abs. 3 S. 1 TMG. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem &uuml;berwiegenden berechtigten Interesse&nbsp;an direkter Kundenkommunikation.&nbsp;<em><strong>Sie haben das Recht aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.</strong></em><br />
Sie k&ouml;nnen die Speicherung von Cookies durch die Auswahl entsprechender technischer Einstellungen Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht s&auml;mtliche Funktionen dieser Website vollumf&auml;nglich werden nutzen k&ouml;nnen.</span><br />
&nbsp;</div>
<br />
<span style="font-size:20px;"><strong><em>Plug-ins und Sonstiges</em></strong></span><br />
<br />
<div>
<span style="font-size:14px;"><strong>Verwendung des Google Tag Managers</strong><br />
Wir verwenden auf unserer Website den Google Tag Manager der Google LLC. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; &bdquo;Google&ldquo;). Soweit Sie Ihren gew&ouml;hnlichen Aufenthalt im Europ&auml;ischen Wirtschaftsraum oder der Schweiz haben, ist Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland) der f&uuml;r Ihre Daten zust&auml;ndige Verantwortliche. Google Ireland Limited ist demnach das mit Google verbundene Unternehmen, welches f&uuml;r die Verarbeitung Ihrer Daten und die Einhaltung der anwendbaren Datenschutzgesetze verantwortlich ist.<br />
Mit dieser Anwendung werden JavaScript-Tags und HTML-Tags verwaltet, die zur Implementierung insbesondere von Tracking- und Analyse-Tools verwendet werden. Die Datenverarbeitung dient dem Zweck der bedarfsgerechten Gestaltung und der Optimierung unserer Website.<br />
Der Google Tag Manager selbst speichert weder Cookies noch werden hierdurch personenbezogene Daten verarbeitet. Er erm&ouml;glicht jedoch die Ausl&ouml;sung weiterer Tags, die personenbezogene Daten erheben und verarbeiten k&ouml;nnen.<br />
N&auml;here Informationen zu Nutzungsbedingungen und Datenschutz finden Sie <a href="https://www.google.com/intl/de/tagmanager/use-policy.html" target="_blank">hier</a>.</span><br />
&nbsp;</div>
<p dir="ltr">
<strong><span style="font-size:14px;">Verwendung von Cloudflare</span></strong><br />
<span style="font-size: 14px;">Wir verwenden auf unserer Website das Content Delivery Network Cloudflare CDN der Cloudflare Inc. (101 Townsend St, San Francisco, CA 94107, USA; &ldquo;Cloudflare&rdquo;). Dabei handelt es sich um ein &uuml;berregionales Netz von Servern in verschiedenen Rechenzentren, mit denen sich unser Webserver verbindet und &uuml;ber die bestimmte Inhalte unserer Webseite ausgeliefert werden.</span><br />
<span style="font-size: 14px;">Die Datenverarbeitung dient dem Zweck, die Ladezeiten unserer Webseite zu optimieren und unser Angebot damit nutzerfreundlicher zu gestalten.</span><br />
<span style="font-size: 14px;">Dabei k&ouml;nnen unter anderem folgende Informationen erhoben werden: IP-Adresse, Systemkonfigurationsinformationen, Informationen &uuml;ber den Traffic von und zu Kundenwebsites (sog. Server-Logfiles).&nbsp;</span><br />
<span style="font-size: 14px;">Ihre Daten werden gegebenenfalls in die USA &uuml;bermi</span><span style="font-size:14px;">ttelt.&nbsp;F&uuml;r die USA ist kein Angemessenheitsbeschluss der EU-Kommission vorhanden. Die Daten&uuml;bermittlung erfolgt u.a auf Grundlage von Standardvertragsklauseln als geeignete Garantien f&uuml;r den Schutz der personenbezogenen Daten, einsehbar unter: <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_de" target="_blank">https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_de</a>.</span><br />
<span style="font-size: 14px;">Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt auf Grundlage des &sect; 15 Abs. 3 S. 1 TMG. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem &uuml;berwiegenden berechtigten Interesse</span><span style="font-size: 14px;">&nbsp;an der bedarfsgerechten und zielgerichteten Gestaltung der Website. <strong><em>Sie haben das Recht aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 Abs. 1 lit. f DSGVO beruhenden Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.</em></strong></span><br />
<span style="font-size: 14px;">N&auml;here Informationen zum Datenschutz bei der Verwendung von Cloudflare finden Sie unter </span><a href="https://www.cloudflare.com/de-de/privacypolicy/" style="font-size: 14px;" target="_blank">https://www.cloudflare.com/de-de/privacypolicy/</a><span style="font-size: 14px;">.</span><br />
&nbsp;</p>
<span style="font-size:14px;"><strong>Verwendung von GoogleMaps </strong><br />
Wir verwenden auf unserer Website die Funktion zur Einbettung von GoogleMaps-Karten der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; &bdquo;Google&ldquo;).<br />
Soweit Sie Ihren gew&ouml;hnlichen Aufenthalt im Europ&auml;ischen Wirtschaftsraum oder der Schweiz haben, ist Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland) der f&uuml;r Ihre Daten zust&auml;ndige Verantwortliche. Google Ireland Limited ist demnach das mit Google verbundene Unternehmen, welches f&uuml;r die Verarbeitung Ihrer Daten und die Einhaltung der anwendbaren Datenschutzgesetze verantwortlich ist.<br />
Die Funktion erm&ouml;glicht die visuelle Darstellung von geographischen Informationen und interaktiven Landkarten. Dabei werden von Google bei Aufrufen der Seiten, in die GoogleMaps-Karten eingebunden sind, auch Daten der Besucher der Websites erhoben, verarbeitet und genutzt.<br />
Ihre Daten werden dabei gegebenenfalls auch in die USA &uuml;bermittelt.&nbsp;F&uuml;r die USA ist kein Angemessenheitsbeschluss der EU-Kommission vorhanden. Die Daten&uuml;bermittlung erfolgt u.a auf Grundlage von Standardvertragsklauseln als geeignete Garantien f&uuml;r den Schutz der personenbezogenen Daten, einsehbar unter: <a href="https://policies.google.com/privacy/frameworks" target="_blank">https://policies.google.com/privacy/frameworks</a>.<br />
Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt auf Grundlage des &sect; 15 Abs. 3 S. 1 TMG. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem &uuml;berwiegenden berechtigten Interesse an der bedarfsgerechten und zielgerichteten Gestaltung der Website. <em><strong>Sie haben das Recht aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.</strong></em><br />
N&auml;here Informationen zur Erhebung und Nutzung der Daten durch Google finden Sie in den Datenschutzhinweisen von Google unter <a href="https://www.google.com/privacypolicy.html" target="_blank">https://www.google.com/privacypolicy.html</a>. Dort haben Sie auch im Datenschutzcenter die M&ouml;glichkeit, Ihre Einstellungen zu ver&auml;ndern, so dass Sie Ihre von Google verarbeiteten Daten verwalten und sch&uuml;tzen k&ouml;nnen.</span><br />
<br />
<span style="font-size:14px;"><strong>Verwendung von YouTube</strong><br />
Wir verwenden auf unserer Website die Funktion zur Einbettung von YouTube-Videos der Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland; &bdquo;YouTube&ldquo;).YouTube ist ein mit der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; &ldquo;Google&rdquo;) verbundenes Unternehmen.<br />
Die Funktion zeigt bei YouTube hinterlegte Videos in einem iFrame auf der Website an. Dabei ist die Option &bdquo;Erweiterter Datenschutzmodus&ldquo; aktiviert. Dadurch werden von YouTube keine Informationen &uuml;ber die Besucher der Website gespeichert. Erst wenn Sie sich ein Video ansehen, werden Informationen dar&uuml;ber an YouTube &uuml;bermittelt und dort gespeichert. Ihre Daten werden gegebenenfalls in die USA &uuml;bermittelt.&nbsp;F&uuml;r die USA ist kein Angemessenheitsbeschluss der EU-Kommission vorhanden. Die Daten&uuml;bermittlung erfolgt u.a auf Grundlage von Standardvertragsklauseln als geeignete Garantien f&uuml;r den Schutz der personenbezogenen Daten, einsehbar unter: <a href="https://policies.google.com/privacy/frameworks" target="_blank">https://policies.google.com/privacy/frameworks</a>.<br />
Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt auf Grundlage des &sect; 15 Abs. 3 S. 1 TMG. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem &uuml;berwiegenden berechtigten Interesse an der bedarfsgerechten und zielgerichteten Gestaltung der Website. <em><strong>Sie haben das Recht aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.</strong></em><br />
N&auml;here Informationen zur Erhebung und Nutzung der Daten durch YouTube und Google, &uuml;ber Ihre diesbez&uuml;glichen Rechte und M&ouml;glichkeiten zum Schutz Ihrer Privatsph&auml;re finden Sie in den Datenschutzhinweisen von YouTube unter <a href="https://www.youtube.com/t/privacy" target="_blank">https://www.youtube.com/t/privacy</a>.</span><br />
<br />
<div>
<span style="font-size:14px;"><strong>Verwendung von Google Fonts</strong><br />
Wir verwenden auf unserer Website Google Fonts der Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland; &ldquo;Google&rdquo;).<br />
Die Datenverarbeitung dient dem Zweck der einheitlichen Darstellung von Schriftarten auf unserer Website. Um die Schriftarten zu laden, wird beim Seitenaufruf eine Verbindung zu Servern von Google hergestellt. Hierbei k&ouml;nnen Cookies eingesetzt werden. Dabei wird u.a. Ihre IP-Adresse sowie Informationen zu dem von Ihnen genutzten Browser verarbeitet und an Google &uuml;bermittelt. Diese Daten werden nicht mit Ihrem Google-Konto verkn&uuml;pft.<br />
Ihre Daten werden gegebenenfalls in die USA &uuml;bermittelt. F&uuml;r die USA ist kein Angemessenheitsbeschluss der EU-Kommission vorhanden. Die Daten&uuml;bermittlung erfolgt u.a auf Grundlage von Standardvertragsklauseln als geeignete Garantien f&uuml;r den Schutz der personenbezogenen Daten, einsehbar unter: <a href="https://policies.google.com/privacy/frameworks" target="_blank">https://policies.google.com/privacy/frameworks</a>.</span><br />
<span style="font-size: 14px;">Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt auf Grundlage des &sect; 15 Abs. 3 S. 1 TMG. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem &uuml;berwiegenden berechtigten Interesse</span><span style="font-size:14px;">&nbsp;an einer nutzerfreundlichen und &auml;sthetischen Gestaltung unserer Website. <em><strong>Sie haben das Recht aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen diese auf Art. 6 Abs. 1 lit. f DSGVO beruhende Verarbeitung Sie betreffender personenbezogener Daten durch eine Mitteilung an uns zu widersprechen.</strong></em><br />
N&auml;here Informationen zur Datenverarbeitung und zum Datenschutz finden Sie unter <a href="https://www.google.de/intl/de/policies/" target="_blank">https://www.google.de/intl/de/policies/</a> sowie unter <a href="https://developers.google.com/fonts/faq" target="_blank">https://developers.google.com/fonts/faq</a>.</span><br />
&nbsp;</div>
<br />
<span style="font-size:20px;"><strong><em>Betroffenenrechte und Speicherdauer</em></strong></span><br />
<br />
<span style="font-size:14px;"><strong>Dauer der Speicherung</strong><br />
Nach vollst&auml;ndiger Vertragsabwicklung werden die Daten zun&auml;chst f&uuml;r die Dauer der Gew&auml;hrleistungsfrist, danach unter Ber&uuml;cksichtigung gesetzlicher, insbesondere steuer- und handelsrechtlicher Aufbewahrungsfristen gespeichert und dann nach Fristablauf gel&ouml;scht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.</span><br />
<br />
<span style="font-size:14px;"><strong>Rechte der betroffenen Person</strong><br />
Ihnen stehen bei Vorliegen der gesetzlichen Voraussetzungen folgende Rechte nach Art. 15 bis 20 DSGVO zu: Recht auf Auskunft, auf Berichtigung, auf L&ouml;schung, auf Einschr&auml;nkung der Verarbeitung, auf Daten&uuml;bertragbarkeit.<br />
Au&szlig;erdem steht Ihnen nach Art. 21 Abs. 1 DSGVO ein Widerspruchsrecht gegen die Verarbeitungen zu, die auf Art. 6 Abs. 1 f DSGVO beruhen, sowie gegen die Verarbeitung zum Zwecke von Direktwerbung.</span><br />
<br />
<span style="font-size:14px;"><strong>Beschwerderecht bei der Aufsichtsbeh&ouml;rde</strong><br />
Sie haben gem&auml;&szlig; Art. 77 DSGVO das Recht, sich bei der Aufsichtsbeh&ouml;rde zu beschweren, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten nicht rechtm&auml;&szlig;ig erfolgt.</span><br />
<br />
<span style="font-size:14px;"><strong>Widerspruchsrecht</strong><br />
Beruhen die hier aufgef&uuml;hrten personenbezogenen Datenverarbeitungen auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO, haben Sie das Recht aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, jederzeit diesen Verarbeitungen mit Wirkung f&uuml;r die Zukunft zu widersprechen.<br />
Nach erfolgtem Widerspruch wird die Verarbeitung der betroffenen Daten beendet, es sei denn, wir k&ouml;nnen zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen, die Ihren Interessen, Rechten und Freiheiten &uuml;berwiegen, oder wenn die Verarbeitung der Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen dient.</span><br />
<br />
<span style="font-size:14px;">Erfolgt die personenbezogene Datenverarbeitung zu Zwecken der Direktwerbung, k&ouml;nnen Sie dieser Verarbeitung jederzeit durch Mitteilung an uns widersprechen. Nach erfolgtem Widerspruch beenden wir die Verarbeitung der betroffenen Daten zum Zwecke der Direktwerbung.</span><br />
<br />
<span style="font-size:14px;">letzte Aktualisierung: 27.10.2020</span><br />
<br />
  <!-- text ende -->
        </div>
      </div>
    </div>
    <FooterBar></FooterBar>
  </div>
</template>

<script>
import FooterBar from '@/views/footer.vue'

export default {
  components: {
    FooterBar
  }
}
</script>

<style scoped>
</style>
