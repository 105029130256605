<template>
  <div class="section white full-height">
      <div class="container">
        <div class="row">
          <div class="col s12 l6">
            <img class="responsive-img" src="https://dataliv.de/img/karte_m_satellit_2.jpg">
          </div>
          <div class="col s12 l6">
            <br><br><br><br><br><br> <!--
            <p class="caption left-align">"Stellen Sie sich unseren Service wie einen Satelitten vor der auf der Erdumlaufbahn kreist auf ein bestimmtes Gebiet oder Land auf der Erde ausgerichtet ist und dieses Gebiet durchsucht auf der Suche nach Firmen die Ihren Suchkriterien entsprechen.
              Hat ein Unternehmen einen Fingerabdruck im Internet hinterlassen finden wir es."
            </p> -->
            <p class="caption left-align">"Think of our service as a satellite orbiting the earth aimed at a specific area or country on earth and searching that area looking for companies that match your search criteria.
              If a company has left a fingerprint on the Internet, we find it."
              </p>
            <!--
            <p class="caption">We scan a city, a region or even a whole country for you and the art of business companys you are searching for.
                So you get a full market view of how many and which companys in this region are in business.
            </p>
            <p class="caption">After your Order we start a new scan on this region, so you can be sure the data you receive is up to date.
            </p>
            <p class="caption">To Collect the Data wie work with newest Technology and K.I. Algorithms that found >90% of the companys in your prefered region.
            </p>
            <p class="caption">You will receive the company name, street, postal code, location, telephone number, website, email adresses and persons working at the company (if found).
            </p>
            -->
          </div>
        </div>
      </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
    // HelloWorld
  }
}
</script>
