<template>
  <div class="about">
      <div class="section white full-height">
      <div class="row valign">
        <div class="col s8 offset-s2 m6 offset-m3 blog-body">
        <!-- asdf --><br>
            <p class="font120">We live technology.</p>
            <br>
         <div class="row">
                <div class="col s12 m3">
                  <div class="card">
                    <div class="card-image">
                      <img src="https://dataliv.de/images/geiger1.jpg">
                    </div>
                  </div>
                </div>
        <p class="left-align">Our home is the technology-oriented business park with digital start-up center in Deggendorf.</p>
        <!--
        <p class="left-align">In unserem täglichen Leben sind wir ständig auf der Suche wie wir mit neuen Technologien und Lösungen Unternehmen bei ihren täglichen Aufgaben unterstützen können. In diesem Zuge ist auch der Firmen Scanner den Sie hier sehen entstanden er soll Firmen die im B2B Bereich tätig sind dabei unterstützen sich auf den Vertrieb ihrer Produkte und Dienstleistungen zu konzentrieren während wir
            die benötigten Daten der Zielgruppenkunden liefern.</p> -->
        <p class="left-align">In our daily life we are constantly looking for new technologies and solutions to support companies in their daily tasks. In the course of this, the company scanner you see here was created to help companies that are active in the B2B area to concentrate on the sales of their products and services while we provide the required data of the target group customers.
            deliver the required data of the target group customers.
            </p>
            </div>
        <!-- asdf -->
        <div class="card">
            <div class="card-content">
               <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d42011.94710138407!2d12.963020776394169!3d48.84397019422381!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47751172a61f6e97%3A0x72d0485b40c7b3ce!2sITC%20Innovations%20Technologie%20Campus%20GmbH!5e0!3m2!1sde!2sde!4v1615374521975!5m2!1sde!2sde" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </div>
        </div>
        </div>
      </div>
      </div>
    <FooterBar></FooterBar>
  </div>
</template>

<script>
import FooterBar from '@/views/footer.vue'

export default {
  components: {
    FooterBar
  }
}
</script>

<style scoped>
.font120 {
    font-size:150%;
}
</style>
