<template>
  <div class="about">
    <div class="section white full-height">
      <div class="row valign">
        <div class="col s8 offset-s2 m6 offset-m3 blog-body dsgvo">
          <br><br>
            <!-- text -->
<strong><span style="font-size:18px;">Allgemeine Gesch&auml;ftsbedingungen und Kundeninformationen</span></strong><br />
<br />
<span style="font-size:16px;"><strong>I. Allgemeine Gesch&auml;ftsbedingungen</strong></span><br />
<br />
<strong><span style="font-size: medium;">&sect; 1 Grundlegende Bestimmungen</span></strong><br />
<br />
<span style="font-size: 14px;"><span><strong>(1)</strong> &nbsp;Die nachstehenden Gesch&auml;ftsbedingungen gelten f&uuml;r Vertr&auml;ge, die Sie mit uns als Anbieter (C.Geiger &amp; R.Lachhammer GbR<span style="font-size:14px;">) </span>&uuml;ber die Internetseite&nbsp;</span></span><span style="font-size: 14px;"><span>dataliv.de </span></span><span style="font-size:14px;"><span>schlie&szlig;en, soweit nicht schriftlich zwischen den Parteien eine Ab&auml;nderung vereinbart wird. Abweichende oder entgegenstehende Gesch&auml;ftsbedingungen sind nur mit unserer ausdr&uuml;cklichen Zustimmung wirksam.</span></span><br />
<br />
<span style="font-size:14px;"><strong>(2)</strong> Wir bieten unsere Produkte nur zum Kauf an, soweit Sie eine nat&uuml;rliche oder juristische Person oder eine rechtsf&auml;hige Personengesellschaft sind, die bei Abschluss des Rechtsgesch&auml;fts in Aus&uuml;bung ihrer gewerblichen oder selbst&auml;ndigen beruflichen T&auml;tigkeit handelt (Unternehmer). Ein Vertragsabschluss mit Verbrauchern ist ausgeschlossen.</span><br />
<br />
<strong><span style="font-size: medium;">&sect; 2 Zustandekommen des Vertrages</span></strong><br />
<br />
<span style="font-size:14px;"><strong><span style="font-size:14px;">(1) </span></strong>Gegenstand des Vertrages ist der Verkauf von Waren</span><span style="font-size: 14px;"></span><span style="font-size:14px;">.&nbsp;</span><span style="font-size: 14px;">Die wesentlichen Merkmale der Ware</span><span style="font-size: 14px;"></span><span style="font-size: 14px;">&nbsp;finden sich im jeweiligen Angebot.</span><br />
<br />
<span style="font-size:14px;"><strong>(2)</strong>&nbsp; Unsere Angebote im Internet sind unverbindlich und kein verbindliches Angebot zum Abschluss eines Vertrages.</span><br />
<br />
<strong><span style="font-size:14px;">(3)&nbsp;</span></strong><span style="font-size: 14px;"> Sie k&ouml;nnen</span><span style="font-size:14px;"> ein verbindliches Vertragsangebot (Bestellung) telefonisch, per E-Mail, per Fax, per Post oder &uuml;ber das Online-Warenkorbsystem abgeben.<br />
<br />
Beim Kauf &uuml;ber das Online-Warenkorbsystem werden die zum Kauf beabsichtigten Waren</span><span style="font-size: 14px;"></span><span style="font-size:14px;">&nbsp;im &bdquo;Warenkorb&quot; abgelegt. &Uuml;ber die entsprechende Schaltfl&auml;che in der Navigationsleiste k&ouml;nnen Sie den &bdquo;Warenkorb&quot; aufrufen und dort jederzeit &Auml;nderungen vornehmen. Nach Aufrufen der Seite &bdquo;Kasse&quot; und der Eingabe der pers&ouml;nlichen Daten sowie der Zahlungs- und Versandbedingungen werden abschlie&szlig;end nochmals alle Bestelldaten auf der Bestell&uuml;bersichtsseite angezeigt<span style="font-style: italic;">.</span></span><br />
<br />
<span style="font-size:14px;">Vor Absenden der Bestellung haben Sie die M&ouml;glichkeit, die Angaben in der Bestell&uuml;bersicht nochmals zu &uuml;berpr&uuml;fen, zu &auml;ndern (auch &uuml;ber die Funktion &quot;zur&uuml;ck&quot; des Internetbrowsers) bzw. die Bestellung abzubrechen. </span><span style="font-size:14px;">Mit dem Absenden der Bestellung &uuml;ber die entsprechende Schaltfl&auml;che geben Sie ein verbindliches Angebot bei uns ab.</span><br />
<span style="font-size:14px;">Sie erhalten zun&auml;chst eine automatische E-Mail &uuml;ber den Eingang ihrer Bestellung, die noch nicht zum Vertragsschluss f&uuml;hrt.</span><br />
<br />
<span style="font-size:14px;"><strong>(4)</strong>&nbsp; Die Annahme des Angebots (und damit der Vertragsabschluss) erfolgt bei Bestellung per Telefon sofort oder sp&auml;testens innerhalb von 5 Tagen durch Best&auml;tigung in Textform (z.B. E-Mail), in welcher Ihnen die Ausf&uuml;hrung der Bestellung oder Auslieferung der Ware</span><span style="font-size:14px;"></span><span style="font-size:14px;">&nbsp;best&auml;tigt wird (Auftragsbest&auml;tigung). </span><br />
<span style="font-size:14px;">Sollten Sie innerhalb dieser Frist keine entsprechende Nachricht erhalten haben, sind Sie nicht mehr an Ihre Bestellung gebunden. Gegebenenfalls bereits erbrachte Leistungen werden in diesem Fall unverz&uuml;glich zur&uuml;ckerstattet. </span><br />
<br />
<span style="font-size:14px;"><strong>(5)</strong> Auf Anfrage erstellen wir Ihnen ein individuelles Angebot, welches Ihnen in Textform zugesandt wird und an das wir uns 5 Tage (soweit im jeweiligen Angebot keine andere Frist ausgewiesen ist) gebunden halten. Sie nehmen das Angebot mit Best&auml;tigung in Textform an.</span><br />
<br />
<span style="font-size:14px;"><strong>(6)</strong> Die Abwicklung der Bestellung und &Uuml;bermittlung aller im Zusammenhang mit dem Vertragsschluss erforderlichen Informationen erfolgt per E-Mail zum Teil automatisiert. Sie haben deshalb sicherzustellen, dass die von Ihnen bei uns hinterlegte E-Mail-Adresse zutreffend ist, der Empfang der E-Mails technisch sichergestellt und insbesondere nicht durch SPAM-Filter verhindert wird.</span><br />
<br />
<span style="font-size:14px;"><span style="font-size:16px;"><strong>&sect; 3&nbsp;</strong></span></span><span style="font-size:14px;"><span style="font-size:16px;"><strong>Individuell gestaltete Waren</strong></span><br />
<br />
<strong><span style="font-size:14px;">(1)</span></strong> Sie stellen uns die f&uuml;r die individuelle Gestaltung der Waren erforderlichen geeigneten Informationen, Texte oder Dateien &uuml;ber das Online-Bestellsystem oder sp&auml;testens unverz&uuml;glich nach Vertragsschluss per E-Mail zur Verf&uuml;gung. Unsere etwaigen Vorgaben zu Dateiformaten sind zu beachten.<br />
<br />
<strong><span style="font-size:14px;">(2)</span></strong> Sie verpflichten sich, keine Daten zu &uuml;bermitteln, deren Inhalt Rechte Dritter (insbesondere Urheberrechte, Namensrechte, Markenrechte) verletzen oder gegen bestehende Gesetze versto&szlig;en. Sie stellen uns ausdr&uuml;cklich von s&auml;mtlichen in diesem Zusammenhang geltend gemachten Anspr&uuml;chen Dritter frei. Das betrifft auch die Kosten der in diesem Zusammenhang erforderlichen rechtlichen Vertretung.<br />
&nbsp;<br />
<strong><span style="font-size:14px;">(3)</span></strong> Wir nehmen keine Pr&uuml;fung der &uuml;bermittelten Daten auf inhaltliche Richtigkeit vor und &uuml;bernehmen insoweit keine Haftung f&uuml;r Fehler.<br />
&nbsp;<br />
<strong><span style="font-size:14px;">(4)</span></strong> Soweit im jeweiligen Angebot angegeben, erhalten Sie von uns eine Korrekturvorlage &uuml;bersandt, die von Ihnen unverz&uuml;glich zu pr&uuml;fen ist. Sind Sie mit dem Entwurf einverstanden, geben Sie die Korrekturvorlage durch Gegenzeichnung in Textform (z.B. E-Mail) zur Ausf&uuml;hrung frei.<br />
Eine Ausf&uuml;hrung der Gestaltungsarbeiten ohne Ihre Freigabe erfolgt nicht.<br />
Sie sind daf&uuml;r verantwortlich, die Korrekturvorlage auf Richtigkeit und Vollst&auml;ndigkeit zu &uuml;berpr&uuml;fen und uns etwaige Fehler mitzuteilen. Wir &uuml;bernehmen keine Haftung f&uuml;r nichtbeanstandete Fehler.</span><br />
<br />
<span style="font-size:16px;"><strong>&sect; 4 Preise, Zahlungsbedingungen und Versandkosten</strong></span><br />
<br />
<span style="font-size:14px;"><strong>(1)</strong> Die in den jeweiligen Angeboten angef&uuml;hrten Preise sowie die Versandkosten stellen Nettopreise dar. Sie beinhalten nicht die gesetzliche Mehrwertsteuer.</span><br />
<br />
<span style="font-size:14px;"><strong>(2)</strong> Die anfallenden Versandkosten sind nicht im Kaufpreis enthalten, sie werden gesondert berechnet, soweit nicht die versandkostenfreie Lieferung zugesagt ist. N&auml;here Einzelheiten finden sich unter einer entsprechend bezeichneten Schaltfl&auml;che auf unserer Internetpr&auml;senz oder im jeweiligen Angebot.</span><br />
<br />
<span style="font-size:14px;"><strong>(3)</strong>&nbsp;Erfolgt die Lieferung in L&auml;nder au&szlig;erhalb der Europ&auml;ischen Union k&ouml;nnen von uns nicht zu vertretende weitere Kosten anfallen, wie z.B. Z&ouml;lle, Steuern oder Geld&uuml;bermittlungsgeb&uuml;hren (&Uuml;berweisungs- oder Wechselkursgeb&uuml;hren der Kreditinstitute), die von Ihnen zu tragen sind.&nbsp;</span><br />
<br />
<span style="font-size:14px;"><strong>(4)</strong>&nbsp;Entstandene Kosten der Geld&uuml;bermittlung&nbsp;(&Uuml;berweisungs- oder Wechselkursgeb&uuml;hren der Kreditinstitute)&nbsp;sind von Ihnen&nbsp;in den F&auml;llen zu tragen, in denen die Lieferung in einen EU-Mitgliedsstaat erfolgt, die Zahlung aber au&szlig;erhalb der Europ&auml;ischen Union veranlasst wurde.</span><br />
<br />
<span style="font-size:14px;"><strong>(5)</strong> Sie haben die unter einer entsprechend bezeichneten Schaltfl&auml;che auf unserer Internetpr&auml;senz oder im jeweiligen Angebot ausgewiesenen Zahlungsm&ouml;glichkeiten. Soweit bei den einzelnen Zahlungsarten oder auf der Rechnung keine andere Zahlungsfrist angegeben ist, sind die Zahlungsanspr&uuml;che aus dem geschlossenen Vertrag sofort zur Zahlung f&auml;llig. Der Abzug von Skonti ist nur zul&auml;ssig, sofern im jeweiligen Angebot oder in der Rechnung ausdr&uuml;cklich ausgewiesen.</span><br />
<br />
<span style="font-size:16px;"><strong>&sect; 5 Lieferbedingungen</strong></span><br />
<br />
<span style="font-size:14px;"><strong>(1)</strong> Die voraussichtliche Lieferfrist ist im jeweiligen Angebot angegeben. Liefertermine und Lieferfristen sind nur verbindlich, wenn sie von uns schriftlich best&auml;tigt wurden. Bei der Zahlart Vorkasse per &Uuml;berweisung erfolgt die Versendung der Ware</span><span style="font-size:14px;">&nbsp;</span><span style="font-size:14px;">erst nach Eingang des vollst&auml;ndigen Kaufpreises</span><span style="font-size:14px;"></span><span style="font-size:14px;">&nbsp;und der Versandkosten bei uns.</span><br />
<br />
<span style="font-size:14px;"><strong>(2)</strong> Sollte ein von Ihnen bestelltes Produkt wider Erwarten trotz rechtzeitigem Abschluss eines ad&auml;quaten Deckungsgesch&auml;ftes aus einem von uns nicht zu vertretenden Grund nicht verf&uuml;gbar sein, werden Sie unverz&uuml;glich &uuml;ber die Nichtverf&uuml;gbarkeit informiert und im Falle des R&uuml;cktritts etwa bereits geleistete Zahlungen unverz&uuml;glich erstattet.<br />
&nbsp;<br />
<strong>(3)</strong> Die Versendung erfolgt auf Ihre Gefahr. Sofern Sie es w&uuml;nschen, erfolgt der Versand mit einer entsprechenden Transportversicherung, wobei die hierdurch entstehenden Kosten von Ihnen zu &uuml;bernehmen sind.<br />
&nbsp;<br />
<strong>(4)</strong> Teillieferungen sind zul&auml;ssig und k&ouml;nnen von uns selbstst&auml;ndig in Rechnung gestellt werden, sofern Sie hierdurch nicht mit Mehrkosten f&uuml;r den Versand belastet werden.</span><br />
<br />
<span style="font-size:16px;"><strong>&sect; 6 Gew&auml;hrleistung</strong></span><br />
<br />
<strong><span style="font-size:14px;">(1)</span></strong><span style="font-size:14px;"> Die Gew&auml;hrleistungsfrist betr&auml;gt ein Jahr ab Ablieferung der Sache. Die Fristverk&uuml;rzung gilt nicht:</span><br />
<br />
<span style="font-size: 14px;">- </span><span style="font-size: 14px;">f&uuml;r uns zurechenbare schuldhaft verursachte Sch&auml;den aus der Verletzung des Lebens, des K&ouml;rpers oder der Gesundheit und bei vors&auml;tzlich oder grob fahrl&auml;ssig verursachten sonstigen Sch&auml;den;</span><br />
<span style="font-size:14px;">- soweit wir den Mangel arglistig verschwiegen oder eine Garantie f&uuml;r die Beschaffenheit der Sache &uuml;bernommen haben;</span><br />
<span style="font-size: 14px;">- bei Sachen, die entsprechend ihrer &uuml;blichen Verwendungsweise f&uuml;r ein Bauwerk verwendet worden sind und dessen Mangelhaftigkeit verursacht haben;</span><br />
<span style="font-size: 14px;">- bei gesetzlichen R&uuml;ckgriffsanspr&uuml;chen, die Sie im Zusammenhang mit M&auml;ngelrechten gegen uns haben.</span><br />
<br />
<strong><span style="font-size:14px;">(2) </span></strong><span style="font-size:14px;">Als Beschaffenheit der Sache gelten nur unsere eigenen Angaben und die Produktbeschreibung des Herstellers als vereinbart, nicht jedoch sonstige Werbung, &ouml;ffentliche Anpreisungen und &Auml;u&szlig;erungen des Herstellers.<br />
&nbsp;</span><br />
<strong><span style="font-size:14px;">(3) </span></strong><span style="font-size:14px;">Bei M&auml;ngeln leisten wir nach unserer Wahl Gew&auml;hr durch Nachbesserung oder Nachlieferung. Schl&auml;gt die Mangelbeseitigung fehl, k&ouml;nnen Sie nach Ihrer Wahl Minderung verlangen oder vom Vertrag zur&uuml;cktreten. Die M&auml;ngelbeseitigung gilt nach erfolglosem zweiten Versuch als fehlgeschlagen, wenn sich nicht insbesondere aus der Art der Sache oder des Mangels oder den sonstigen Umst&auml;nden etwas anderes ergibt. Im Falle der Nachbesserung m&uuml;ssen wir nicht die erh&ouml;hten Kosten tragen, die durch die Verbringung der Ware an einen anderen Ort als den Erf&uuml;llungsort entstehen, sofern die Verbringung nicht dem bestimmungsgem&auml;&szlig;en Gebrauch der Ware entspricht.</span><br />
<br />
<strong><span style="font-size:16px;">&sect; 7 Zur&uuml;ckbehaltungsrecht, Eigentumsvorbehalt</span></strong><br />
<br />
<span style="font-size:14px;"><strong>(1)</strong> Ein Zur&uuml;ckbehaltungsrecht k&ouml;nnen Sie nur aus&uuml;ben, soweit es sich um Forderungen aus demselben Vertragsverh&auml;ltnis handelt.<br />
&nbsp;<br />
<strong>(2)</strong> Wir behalten uns das Eigentum an der Ware bis zum vollst&auml;ndigen Ausgleich aller Forderungen aus der laufenden Gesch&auml;ftsbeziehung vor. Vor &Uuml;bergang des Eigentums an der Vorbehaltsware ist eine Verpf&auml;ndung oder Sicherheits&uuml;bereignung nicht zul&auml;ssig.<br />
&nbsp;<br />
<strong>(3)</strong> Sie k&ouml;nnen die Ware im ordentlichen Gesch&auml;ftsgang weiterverkaufen. F&uuml;r diesen Fall treten Sie bereits jetzt alle Forderungen in H&ouml;he des Rechnungsbetrages, die Ihnen aus dem Weiterverkauf erwachsen, an uns ab, wir nehmen die Abtretung an. Sie sind weiter zur Einziehung der Forderung erm&auml;chtigt. Soweit Sie Ihren Zahlungsverpflichtungen nicht ordnungsgem&auml;&szlig; nachkommen, behalten wir uns allerdings vor, die Forderung selbst einzuziehen.<br />
&nbsp;<br />
<strong>(4)</strong> Bei Verbindung und Vermischung der Vorbehaltsware erwerben wir Miteigentum an der neuen Sache im Verh&auml;ltnis des Rechnungswertes der Vorbehaltsware zu den anderen verarbeiteten Gegenst&auml;nden zum Zeitpunkt der Verarbeitung.<br />
&nbsp;<br />
<strong>(5)</strong> Wir verpflichten uns, die Ihnen zustehenden Sicherheiten auf Ihr Verlangen insoweit freizugeben, als der realisierbare Wert unserer Sicherheiten die zu sichernde Forderung um mehr als 10% &uuml;bersteigt. Die Auswahl der freizugebenden Sicherheiten obliegt uns.</span><br />
<br />
<strong><span style="font-size: 16px;">&sect; 8 Rechtswahl</span></strong><br />
<br />
<span style="font-size:14px;">Es gilt deutsches Recht unter Ausschluss des UN-Kaufrechts.&nbsp;</span><br />
<br />
<hr /><br />
<br />
<br />
<strong><span style="font-size:18px;">II. Kundeninformationen</span></strong><br />
<br />
<span style="font-size:16px;"><strong>1. Identit&auml;t des Verk&auml;ufers</strong></span><br />
<br />
<span style="font-size:14px;"><span style="font-size:14px;"><span style="font-size:14px;">C.Geiger &amp; R.Lachhammer GbR</span></span><br />
Ulrichsberger Stra&szlig;e 17/G2<br />
94469 Deggendorf<br />
Deutschland<br />
Telefon: <span style="font-size:14px;">015152594231</span><br />
E-Mail: <span style="font-size:14px;">info@dataliv.de</span></span><br />
<span style="font-size:14px;"></span><br />
<br />
<strong><span style="font-size:16px;">2. Informationen zum Zustandekommen des Vertrages</span></strong><br />
<br />
<span style="font-size:14px;">Die technischen Schritte zum Vertragsschluss und der Vertragsschluss selbst</span><span style="font-size:14px;">, sowie die Korrekturm&ouml;glichkeiten</span><span style="font-size:14px;">&nbsp;erfolgen nach Ma&szlig;gabe des &sect; 2 unserer Allgemeinen Gesch&auml;ftsbedingungen (Teil I).</span><br />
<br />
<span style="font-size:16px;"><strong>3. Vertragssprache, Vertragstextspeicherung</strong></span><br />
<br />
<span style="font-size:14px;">3.1. Vertragssprache ist deutsch.</span><br />
<br />
<span style="font-size:14px;">3.2. Der vollst&auml;ndige Vertragstext wird von uns nicht gespeichert. Vor Absenden der Bestellung</span><span style="font-size: 14px;">&nbsp;oder der Anfrage k&ouml;nnen die Vertragsdaten &uuml;ber die Druckfunktion des Browsers ausgedruckt oder elektronisch gesichert werden.&nbsp;</span><br />
<br />
<span style="font-size:14px;">Diese AGB wurden von den auf IT-Recht spezialisierten Juristen des H&auml;ndlerbundes erstellt und werden permanent auf Rechtskonformit&auml;t gepr&uuml;ft. Die H&auml;ndlerbund Management AG garantiert f&uuml;r die Rechtssicherheit der Texte und haftet im Falle von Abmahnungen. N&auml;here Informationen dazu finden Sie unter: http://www.haendlerbund.de/agb-service.</span><br />
<br />
<span style="font-size:14px;">letzte Aktualisierung: <span style="font-size:14px;">27.10.2020</span></span><br />
<br />
<br />
  <!-- text ende -->
        </div>
      </div>
    </div>
    <FooterBar></FooterBar>
  </div>
</template>

<script>
import FooterBar from '@/views/footer.vue'

export default {
  components: {
    FooterBar
  }
}
</script>

<style scoped>
</style>
