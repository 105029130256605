<template>
  <div class="section valign-wrapper white">
      <div class="container">
        <div class="row">
          <p class="black-text caption">What Data does a Company Dataset contain?</p>
          <p class="black-text caption">Company Name, Street, Zip Code, City, State, Country, Website URL, Phone Numbers, E-Mail Addresses, Employee Data see an Example below.</p>
          <div class="col s12 m12">
            <div class="card">
              <div class="card-content">
                <div class="row left-align">
                  <div class="col s12 l3">
                    <p class="black-text bold">Max Mustermann GmbH</p>
                    <p class="black-text">Musterstraße 16</p>
                    <p class="black-text">98765 Musterstadt</p>
                    <p class="black-text">Bayern</p>
                    <p class="black-text">Deutschland</p>
                  </div>
                  <div class="col s12 l6">
                    <p class="black-text"><i class="material-icons left black-text">language</i>www.max-mustermann.de</p>
                    <p class="black-text"><i class="material-icons left black-text">mail</i>info@max-mustermann.de</p>
                    <p class="black-text"><i class="material-icons left black-text">local_phone</i>09876 12345678</p>
                  </div>
                </div>
                <div class="row left-align">
                  <div class="col s12 l3 border">
                    <p class="black-text"><i class="material-icons left black-text">person</i>Max Mustermann</p>
                    <p class="black-text"><i class="material-icons left black-text">description</i>Geschäftsführer</p>
                    <p class="black-text"><i class="material-icons left black-text">mail</i>max@max-mustermann.de</p>
                    <p class="black-text"><i class="material-icons left black-text">local_phone</i>09876 12345666</p>
                  </div>
                  <div class="col s12 l3 border">
                    <p class="black-text"><i class="material-icons left black-text">person</i>Olaf Mustermann</p>
                    <p class="black-text"><i class="material-icons left black-text">description</i>Einkäufer</p>
                    <p class="black-text"><i class="material-icons left black-text">mail</i>olaf@max-mustermann.de</p>
                    <p class="black-text"><i class="material-icons left black-text">local_phone</i>09876 12345677</p>
                  </div>
                  <div class="col s12 l3 border">
                    <p class="black-text"><i class="material-icons left black-text">person</i>Michael Mustermann</p>
                    <p class="black-text"><i class="material-icons left black-text">description</i>-</p>
                    <p class="black-text"><i class="material-icons left black-text">mail</i>michael@max-mustermann.de</p>
                    <p class="black-text"><i class="material-icons left black-text">local_phone</i>-</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          <!--
          <div class="col s12">
            <p class="caption bold">Arbeitet Ihr mit Datenbanken?</p>
            <p class="caption">Nein, für unseren Service verwenden wir keine Datenbanken. Jede Anfrage wird individuell gehandhabt und Ihre angeforderten Daten sind jeweils "frisch" gescannt.</p>
          </div>
          <div class="col s12">
            <p class="bold caption">Welche Kosten Fallen an?</p>
            <p class="caption">Wir vereinbaren entweder einen bestimmten Betrag pro gefundenen Datensatz oder einen festen Betrag für ein definiertes Gebiet.</p>
          </div>
          -->
      </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
    // HelloWorld
  }
}
</script>

<style scoped>
.bold {
    font-weight: bold;
}

.border {
  border-style: dashed;
  border-width: 1px;
  margin:5px;
}

.card {
  border-radius:10px;
}
</style>
