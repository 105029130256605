<template>
<div class="section valign-wrapper indigo accent-4">
      <div class="container">
        <form>
          <div class="row">
              <div class="container">
            <div class="col l8 offset-l2 s12"><h2 class="section-title white-text">Interested in our Service? Contact Us!</h2></div>
            <div class="input-field col s6">
              <input id="company" type="text" v-model="company">
              <label for="company">Your Company</label>
            </div>
            <div class="input-field col s6">
              <input id="name" type="text" v-model="name">
              <label for="name">Your Name</label>
            </div>
            <div class="input-field col s6">
              <input id="email" type="text" v-model="email">
              <label for="email">Your E-Mail*</label>
            </div>
            <div class="input-field col s6">
              <input id="phone" type="text" v-model="phone">
              <label for="phone">Your Phone Number*</label>
            </div>
            <div class="input-field col s12">
              <textarea id="message" class="materialize-textarea" v-model="text"></textarea>
              <label for="message">Descripe the Data you are searching for*</label>
              <a class="waves-effect waves-light btn" @click="send_request()"><i class="material-icons right">send</i>Send</a>
            </div>
            </div>
          </div><!-- row -->
        </form>
      </div>
    </div>
</template>

<script>
// @ is an alias to /src
import M from 'materialize-css'
import axios from 'axios'

export default {
  name: 'Home',
  data: function () {
    return {
      company: '',
      email: '',
      phone: '',
      text: '',
      name: '',
      request: true
    }
  },
  components: {
    // HelloWorld
  },
  mounted: function () {
    M.AutoInit()
  },
  methods: {
    send_request: function () {
      if (this.email !== '' && this.phone !== '' && this.text !== '') {
        this.request = true
      }
      if (this.email === '') {
        this.request = false
      }
      if (this.phone === '') {
        this.request = false
      }
      if (this.text === '') {
        this.request = false
      }
      if (this.request === false) {
        M.toast({ html: 'Pflichtfelder nicht ausgefüllt', classes: 'white black-text bold' })
      } else {
        var config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
        axios.post('https://dataliv.de/api/request.php', {
          company: this.company,
          email: this.email,
          phone: this.phone,
          text: this.text,
          name: this.name
        }, config)
          .then(response => {
            console.log(response.data)
            M.toast({ html: 'Anfrage erhalten', classes: 'white black-text bold' })
            this.company = ''
            this.email = ''
            this.phone = ''
            this.text = ''
            this.name = ''
          })
          .catch(e => {
            // this.errors.push(e)
          })
      }
    }
  }
}
</script>

<style scoped>
.toast {
  font-weight: bold;
}
</style>
