<template>
  <div class="container">
          <transition name="list">
          <div class="card z-depth-0" v-if="show_settings">
            <div class="card-content">
              <br>
              <!-- card content start -->
              <div class="row">
                <h4>Test our Low Orbit Ion Scanner</h4><br>
                <div class="input-field col s12 l5 offset-l1">
                  <i class="material-icons prefix indigo-text text-accent-4">business</i>
                  <input id="icon_api" type="text" v-model="SuchText" @keyup.enter="suche_start">
                  <label for="icon_api">What kind of company you are searching for?</label>
                  <span class="helper-text"></span>
                </div>
                <div class="input-field col s12 l5">
                  <i class="material-icons prefix indigo-text text-accent-4">location_searching</i>
                  <input id="icon_ort" type="text" v-model="ort" @keyup.enter="suche_start">
                  <label for="icon_ort">Where are you searching?</label>
                  <span class="helper-text">region/district/city</span>
                </div>
                <br><br><br>
                <div class="input-field col s12">
                  <!-- <a class="waves-effect waves-green btn-small dark" @click="suche_start">Start Search</a>&nbsp; &nbsp; -->
                  <a class="waves-effect waves-blue btn-small dark" @click="suche_start">Start Scan</a>&nbsp; &nbsp;
                </div>
              </div>
               <!-- card content ende -->
            </div>
            <div class="container">
            <div class="progress indigo accent-4" v-if="loader">
                <div class="indeterminate indigo lighten-5"></div>
                </div>
            </div>
          </div>
          </transition>
        <!-- unternehmenskarten -->
          <!-- <transition-group name="list"> -->
            <div v-if="search">
            <div class="card z-depth-0" v-if="search_started == true">
            <div class="card-content">
              <div class="row">
                 <p class="black-text">Es werden nur Maximal 10 Datensätze angezeigt noch <span class="bold indigo-text text-accent-4">{{abfragen}}</span> Abfragen möglich)</p>
                 <table>
                    <thead>
                    <tr>
                    <th>Company Name</th>
                    <th>Street</th>
                    <th>Post Code</th>
                    <th>Place</th>
                    <th>Telephone</th>
                    <th>Website</th>
                    <th>E-Mail Adresses</th>
                    <th>Mitarbeiter</th>
                    </tr>
                  </thead>
                    <tbody>
                        <FirmRender v-for="(firma, index) in data"
                                    v-bind:key="index"
                                    v-bind:data="firma"></FirmRender>
                    </tbody>
                  </table>
                </div>
              </div>
              </div>
            </div>
            <div class="card z-depth-0" v-if="search == false">
              <div class="card-content">
                  <div class="row">
                      <p class="indigo-text text-accent-4 bold">Momentan sind keine weiteren Abfragen möglich. Versuchen Sie es später noch einmal.</p>
                  </div>
              </div>
            </div>
          <!-- </transition-group> -->
        <!-- unternehmenskarten -->
      </div>
</template>

<script>
import M from 'materialize-css'
import axios from 'axios'
import FirmRender from '@/components/sky_scanner/firm-render.vue'

export default {
  name: 'b2b-finder',
  data: function () {
    return {
      data: Object,
      SuchText: '',
      loader: false,
      show_settings: true,
      ort: '',
      radius: 50000,
      search_started: false,
      ip: '',
      abfragen: '',
      search: true
    }
  },
  components: {
    FirmRender
  },
  mounted: function () {
    this.$emit('navbarchange', 4)
    this.show_settings = true
    M.AutoInit()
  },
  methods: {
    suche_start: function () {
      this.data = ''
      this.api_call()
    },
    api_call: function () {
      this.loader = true
      var config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      axios.post('https://dataliv.de/api/sky_scanner.php', {
        text: this.SuchText,
        ort: this.ort
      }, config)
        .then(response => {
          console.log(response.data)
          this.data = response.data.firmen
          this.loader = false
          this.search_started = true
          this.ip = response.data.ip
          this.abfragen = response.data.abfragen
          this.search = response.data.search
        })
        .catch(e => {
          // this.errors.push(e)
          M.toast({ html: 'Abfragefehler' })
        })
    },
    get_ip: function () {
      this.loader = true
      var config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      axios.post('https://gumbini.de/sky_scanner/get_ip.php', {
        text: this.SuchText,
        ort: this.ort
      }, config)
        .then(response => {
          console.log(this.SuchText)
          console.log(response.data)
          this.loader = false
        })
        .catch(e => {
          // this.errors.push(e)
          M.toast({ html: 'Abfragefehler' })
        })
    },
    next_page: function () {
      this.data = ''
      this.api_call()
    }
  }
}
</script>

<style scoped>
.row {
  width:100% !important;
}
.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active, .list-leave-active {
  transition: all 0.15s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(100px);
}
</style>
