<template>
                      <tr>
                        <td>{{data.name[0]}}</td>
                        <td><span>{{data.street[0]}}</span> <span>{{data.street_number[0]}}</span></td>
                        <td><span>{{data.plz[0]}}</span></td>
                        <td><span>{{data.ort[0]}}</span></td>
                        <td><span>{{data.telefon[0]}}</span></td>
                        <td><a :href="'http://' + data.website" target="_blank" class="hover black-text">{{data.website}}</a></td>
                        <td class="center"><i class="material-icons indigo-text text-accent-4">locked</i></td>
                        <td class="center"><i class="material-icons indigo-text text-accent-4">locked</i></td>
                    </tr>
</template>

<script>

export default {
  name: 'firm-render',
  data: function () {
    return {
      // asdf
    }
  },
  props: {
    data: [Object, Array]
  },
  methods: {
    // asdf
  }
}
</script>

<style scoped>
.hover:hover{
  font-weight: bold;
}
</style>
