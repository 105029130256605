<template>
  <div class="about">
    <div class="section white full-height">
      <div class="row valign">
        <div class="col s8 offset-s2 m6 offset-m3 blog-body dsgvo">
         <br><br>   <!-- text -->
<span style="font-size:18px;"><strong>Zahlung und Versand</strong></span><br />
<br />
<span style="font-size:14px;">Es gelten folgende Bedingungen:</span><br />
<br />
<span style="font-size:14px;">Die Lieferung der Ware erfolgt weltweit.</span><br />
<br />
<strong><span style="font-size:16px;">Versandkosten (zzgl. MwSt.)</span></strong><br />
<br />
<span style="font-size:14px;">Wir berechnen keine Versandkosten.</span><br />
<br />
<span style="font-size: 14px;">Der Mindestbestellwert betr&auml;gt: </span><span style="font-size:14px;">250 &euro;.</span><br />
<br />
<strong><span style="font-size:18px;">Akzeptierte Zahlungsm&ouml;glichkeiten</span></strong><br />
<br />
<span style="font-size:14px;"><strong>-&nbsp; </strong>Vorkasse per &Uuml;berweisung</span><br />
<span style="font-size:14px;"><strong>-</strong></span><strong>&nbsp; </strong><span style="font-size: 14px;">Zahlung per Rechnung</span><br />
<br />
<br />
<strong><span style="font-size:14px;">Weitere Einzelheiten zur Zahlung</span></strong><br />
<br />
<span style="font-size: 14px;">Die Zahlung per Rechnung ist </span><span style="font-size:14px;">ab der 2. Bestellung</span> <span style="font-size:14px;">m&ouml;glich.</span><br />
<br />
<span style="font-size:14px;">Der Rechnungsbetrag ist bei Zahlung auf Rechnung innerhalb von 14 Tagen auszugleichen.</span><br />
<br />
<br />
<span style="font-size:14px;">Bei Fragen finden Sie unsere Kontaktdaten im Impressum.</span><br />
<br />  <!-- text ende -->
        </div>
      </div>
    </div>
    <FooterBar></FooterBar>
  </div>
</template>

<script>
import FooterBar from '@/views/footer.vue'

export default {
  components: {
    FooterBar
  }
}
</script>

<style scoped>
</style>
