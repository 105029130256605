<template>
  <div id="app">
    <NavBar></NavBar>
    <router-view></router-view>
    <VueCookieAcceptDecline
    :ref="'Dataliv_01'"
    :elementId="'Dataliv_01'"
    :debug="false"
    :position="'bottom-left'"
    :type="'floating'"
    :disableDecline="false"
    :transitionName="'slideFromBottom'"
    :showPostponeButton="false"
    @status="cookieStatus"
    @clicked-accept="cookieClickedAccept"
    @clicked-decline="cookieClickedDecline">

    <!-- Optional -->
    <div slot="postponeContent">
        &times;
    </div>

    <!-- Optional -->
    <div slot="message">
        Wir verwenden Cookies, um Ihnen das beste Erlebnis auf unserer Website zu bieten. <router-link tag="a" to="/dsgvo">Learn More...</router-link>
    </div>

    <!-- Optional -->
    <div slot="declineContent">
       Ablehnen
    </div>

    <!-- Optional -->
    <div slot="acceptContent">
        Akzeptieren
    </div>
</VueCookieAcceptDecline>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/views/navbar.vue'
import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'
// import FooterBar from '@/views/footer.vue'
import Tawk from 'vue-tawk'
import Vue from 'vue/dist/vue'

export default {
  name: 'Index',
  components: {
    NavBar,
    VueCookieAcceptDecline
  },
  mounted: function () {
    // asdf
  },
  methods: {
    cookieClickedAccept: function () {
      console.log('hello')
      this.tawk_start()
    },
    cookieClickedDecline: function () {
      console.log('hello111111')
    },
    cookieStatus: function (val) {
      if (val === 'accept') {
        this.tawk_start()
      }
    },
    tawk_start: function () {
      window.Vue = Vue
      Vue.use(Tawk, {
        tawkSrc: 'https://embed.tawk.to/607d8d5ff7ce1827093bd9b4/1f3l70f6m'
      })
    }
  }
}
</script>

<style>

.bold {
  font-weight: bold;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

</style>
