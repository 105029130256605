<template>
  <footer class="page-footer indigo accent-4">
      <div class="container">
        <div class="row">
          <div class="col s6 m3">
            <!-- <img class="materialize-logo" src="images/materialize-teal.png" alt=""> -->
            <p><a class="waves-effect waves-light btn"><i class="material-icons left">language</i>Dataliv - We deliver value.</a></p>
            <p>
            <a href="https://www.haendlerbund.de/faircommerce" target="_blank">
            <img src="../assets/fc_logo.png" title="Fair Commerce Logo" height="100px"
            alt="Fair Commerce Logo" border="0" />
            </a>
            </p>
          </div>
          <div class="col s6 m3">
            <h5 class="white-text">Office Opening Hours</h5>
            <ul>
              <li><a class="white-text">Mon - Thu: 09:00 - 17:00 Uhr</a></li>
              <li><a class="white-text">Fr. 09:00 - 16:00 Uhr</a></li>
            </ul>
          </div>
          <div class="col s6 m2 left-align">
            <h5 class="white-text">Contact</h5>
            <ul>
              <li><a class="white-text">Tel: 0991 20092732</a></li>
              <li><a class="white-text">E-Mail: info@dataliv.de</a></li>
            </ul>
          </div>
          <div class="col s6 m2">
            <ul>
              <router-link tag="li" to="/agb"><a class="white-text">AGB</a></router-link>
               <router-link tag="li" to="/versand"><a class="white-text">Versand</a></router-link>
               <router-link tag="li" to="/dsgvo"><a class="white-text">Datenschutz</a></router-link>
               <router-link tag="li" to="/impressum"><a class="white-text">Impressum</a></router-link>
            </ul>
          </div>
          <div class="col s6 m2">
            <!-- Beginn des H&auml;ndlerbund HTML-Code -->
            <!-- Der HTML-Code darf im Sinne der einwandfreien Funktionalität nicht ver&auml;ndert werden! -->
            <a href="https://www.haendlerbund.de/mitglied/show.php?uuid=13c7ed53-a1c2-11eb-a4fa-9c5c8e4fb375-3212923848" target="_blank">
            <img src="https://www.haendlerbund.de/mitglied/logo.php?uuid=13c7ed53-a1c2-11eb-a4fa-9c5c8e4fb375-3212923848&size=120&variant=1" title="H&auml;ndlerbund Mitglied"
            alt="Mitglied im H&auml;ndlerbund" hspace="5" vspace="5" border="0" />
            </a>
            <!-- Ende des H&auml;ndlerbund HTML-Code -->
          </div>
        </div>
      </div>
    </footer>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'footer',
  components: {
    // HelloWorld
  }
}
</script>

<style scoped>

</style>
