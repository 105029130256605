<template>
  <div class="section valign-wrapper white">
      <div class="container">
        <div class="row">
          <div class="col s12"><!-- <h2 class="section-title">Features</h2> -->
          </div>
          <div class="col s12 l8 offset-l2"> <!--
            <p class="caption">Kundenakquise ist hart. Egal ob telefonischer Kontakt oder E-Mail Marketing. Wo Erfolgsquoten im einstelligen Prozentbereich als gut gelten
              ist eine stabile Datenbasis unerlässlich. Wer im B2B Umfeld tätig ist kommt schnell auf die Frage wie eine gute, aktuelle Kundendatenbasis generieren.
              Wir glauben an die Wertvollheit von Daten. Gute und aktuelle Unternehmensdatensätze können sich zum Schlüsselfaktor im B2B Marketing entwickeln. Wir haben uns
              darauf spezialisiert top aktuelle und komplett Marktabdeckende Firmen Daten zu beschaffen.
            </p> -->
            <p class="caption">Customer acquisition is hard. No matter whether it's telephone contact or e-mail marketing. Where success rates in the single-digit percentage range are considered good
              a stable database is essential. Anyone working in the B2B environment quickly comes to the question of how to generate a good, up-to-date customer database.
              We believe in the value of data. Good and up-to-date company data sets can become a key factor in B2B marketing. We have specialized
              in providing up-to-date and complete market coverage company data.
              </p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
    // HelloWorld
  }
}
</script>
