<template>
  <div class="about">
    <div class="section white full-height">
      <div class="row valign">
        <div class="col s8 offset-s2 m6 offset-m3 blog-body">
          <p class="font120">Impressum</p>
<span style="font-size: 14px;"><strong><span style="font-size:16px;">Impressum</span></strong></span><br />
<br />
<span style="font-size: 14px;"><strong><span style="font-size:16px;"><span style="font-size:12px;">Gesetzliche Anbieterkennung:</span></span></strong></span><br />
<br />
<span style="font-size: 14px;"><strong>C.Geiger &amp; R.Lachhammer GbR<br />
vertreten durch die Gesellschafter: Christoph Geiger</strong></span><strong><span style="font-size: 14px;"></span><span style="font-size: 14px;"></span><span style="font-size: 14px;"></span><span style="font-size: 14px;"></span><span style="font-size: 14px;"></span><br />
<span style="font-size: 14px;">Ulrichsberger Stra&szlig;e 17/G2<br />
94469 Deggendorf<br />
Deutschland</span><br />
<span style="font-size: 14px;">Telefon: 015152594231</span></strong><br />
<strong><span style="font-size: 14px;"><br />
E-Mail: </span></strong><strong><span style="font-size:14px;">info@dataliv.de</span></strong><strong><span style="font-size:14px;"><br />
USt-IdNr.: DE279645313</span></strong><strong><span style="font-size: 14px;"></span></strong><br />
<strong><span style="font-size:14px;">Wi-ID-Nr.: 108/160/57707</span><span style="font-size: 14px;"></span></strong><strong><span style="font-size: 14px;"></span></strong><br />
<br />
<span style="font-size:14px;"></span><br />
<br />
<span style="font-size: 14px;"></span><br />
<br />
<span style="font-size:14px;"><strong>Wir sind seit&nbsp;</strong></span><span style="font-size:14px;"><strong>21.04.2021&nbsp;</strong></span><span style="font-size:14px;"><strong>Mitglied der Initiative &quot;FairCommerce&quot;.</strong><br />
N&auml;here Informationen hierzu finden Sie unter&nbsp;</span><a href="https://www.fair-commerce.de/" style="font-size: 14px;" target="_blank">www.fair-commerce.de</a><span style="font-size:14px;">.</span><br />
<br />  <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
        </div>
      </div>
    </div>
    <FooterBar></FooterBar>
  </div>
</template>

<script>
import FooterBar from '@/views/footer.vue'

export default {
  components: {
    FooterBar
  }
}
</script>

<style scoped>
.font120 {
    font-size:150%;
}
</style>
