<template>
  <div class="home">
    <div class="section header indigo accent-4">
      <!-- <img class="background" src="http://placehold.it/1440x900" alt=""> -->
      <!--
      <video class="background" autoplay muted loop id="myVideo">
        <source src="https://dataliv.de/img/skyscanner2.mp4" type="video/mp4">
      </video>
      -->
      <div class="header-wrapper row valign-wrapper">
        <div class="col s12">
       <h1 class="white-text">Business Data Agency</h1>
          <!-- <span class="tagline">We scan the globe for you.</span> -->
          <!-- <span class="tagline">Premium Business Daten.</span> -->
          <span class="tagline">We deliver value.</span>
          <button class="read-more"><i class="icon-caret-down white-text"></i></button>
        </div>
      </div>
    </div>
      <!-- <TextBlock></TextBlock> -->
      <Why></Why>
      <HowWorks></HowWorks>
      <TextBlock2></TextBlock2>
      <div class="section white full-height">
      <SkyScanner></SkyScanner>
      </div>
      <ContactBlock></ContactBlock>
    <FooterBar></FooterBar>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import FooterBar from '@/views/footer.vue'
import SkyScanner from '@/components/sky_scanner/b2b-finder.vue'
import TextBlock2 from '@/components/TextBlock2.vue'
import ContactBlock from '@/components/ContactBlock.vue'
import HowWorks from '@/components/HowWorks.vue'
import Why from '@/components/why.vue'

export default {
  name: 'Index',
  components: {
    FooterBar,
    SkyScanner,
    TextBlock2,
    ContactBlock,
    HowWorks,
    Why
  }
}
</script>

<style scoped>
.section {
  min-height:100vh;
}
.home {
  height:100vh;
}
.background {
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.deep-blue {
  background-color:#1a0081;
}
</style>
