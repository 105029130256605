import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Impressum from '../views/impressum.vue'
import dsgvo from '../views/dsgvo.vue'
import agb from '../views/agb.vue'
import versand from '../views/versand.vue'
import wir from '../views/uber-uns.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/wir',
    name: 'wir',
    component: wir
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum
  },
  {
    path: '/agb',
    name: 'agb',
    component: agb
  },
  {
    path: '/versand',
    name: 'versand',
    component: versand
  },
  {
    path: '/dsgvo',
    name: 'DSGVO',
    component: dsgvo
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
