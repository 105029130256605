<template>
  <div>
    <nav class="navbar navbar-solid-transition indigo accent-4">
      <div class="nav-wrapper">
        <a class="brand-logo bold"><i class="material-icons">language</i>Dataliv</a>
        <!-- <a href="horizontal-half.html" class="brand-logo"><i class="icon-diamond"></i></a> -->
        <ul id="nav-mobile" class="right hide-on-med-and-down">
          <router-link tag="li" to="/"><a class="bold">Home</a></router-link>
          <router-link tag="li" to="/wir"><a class="bold">Über Uns</a></router-link>
            <router-link tag="li" to="/impressum"><a class="bold">Impressum</a></router-link>
        </ul>

        <ul id="pages" class="dropdown-content">
          <router-link tag="li" to="/"><a class="grey-text text-darken-2 bold">Home</a></router-link>
          <router-link tag="li" to="/wir"><a class="grey-text text-darken-2 bold">Über Uns</a></router-link>
            <router-link tag="li" to="/impressum"><a class="grey-text text-darken-2 bold">Impressum</a></router-link>
        </ul>

        <a href="#" data-target="slide-out" class="sidenav-trigger button-collapse right"><i class="material-icons white-text">menu</i></a>
      </div>
    </nav>
    <ul id="slide-out" class="sidenav">
      <router-link tag="li" to="/"><a class="bold">Home</a></router-link>
          <router-link tag="li" to="/wir"><a class="bold">Über Uns</a></router-link>
            <router-link tag="li" to="/impressum"><a class="bold">Impressum</a></router-link>
    </ul>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Index',
  components: {
    // HelloWorld
  }
}
</script>

<style scoped>
.border {
  text-shadow: -0.5px 0 white, 0 0.5px white, 0.5px 0 white, 0 -0.5px white;
  opacity: 0.5;
}

.brand-logo {
  font-size:130%;
}
</style>
